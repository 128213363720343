<h3 i18n mat-dialog-title>Compose offer document</h3>
<mat-dialog-content>

    <mat-form-field class="document-editor-field">
        <mat-label>Featured product</mat-label>
        <mat-select [(value)]="productSelected">
            <mat-option *ngFor="let product of products" [value]="product">
                {{ product.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div *ngIf="images.length === 0" class="no-images-message">
        No images found for this Budget.
    </div>

    <h4 *ngIf="images.length > 0">Images</h4>

    <div *ngIf="images.length > 0" class="image-list-container">
        <ul class="image-list">
            <li *ngFor="let img of imageList; let i = index">
                <button (click)="removeImage(i)" class="image-selector-remove">
                    <mat-icon>clear</mat-icon>
                </button>
                <div class="image-thumb">
                    <img [alt]="img.title" [src]="img.src"/>
                </div>
                <div class="image-title-container">
                    <mat-form-field>
                        <input [(ngModel)]="img.title" matInput placeholder="Image title">
                    </mat-form-field>
                </div>
            </li>
            <li>
                <div class="image-thumb">
                    <button (click)="toggleImageSelector(target)" class="image-add-button">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
                <div class="image-title-container image-title-container--new-image">
                    <p class="image-title image-title--new-image">
                        Add image
                    </p>
                </div>
            </li>
        </ul>

        <div #target [class.isOpen]="imageSelectorOpen" class="image-selector">
            <ul class="image-selector-grid">
                <li *ngFor="let img of images; let i = index">

                    <div (click)="addImage(i)" class="image-selector-thumb">
                        <img [alt]="img.title" [src]="img.src">
                    </div>
                    <div class="image-selector-title">
                        <p>
                            {{ img.title }}
                        </p>
                    </div>

                </li>
            </ul>
        </div>
    </div>


</mat-dialog-content>
<mat-dialog-actions>
    <button (click)="save()" [disabled]="productSelected === undefined" i18n mat-raised-button type="submit">Save
    </button>
</mat-dialog-actions>
