import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Project} from '../interfaces';
import {ContactPickerDialogComponent} from '../contact-picker-dialog/contact-picker-dialog.component';



@Component({
    selector: 'app-archive-project-dialog',
    template: `<h3 mat-dialog-title i18n>Archive Project</h3>
  <mat-dialog-content>
    <mat-form-field>
      <input type="number" placeholder="Total Invoiced" aria-label="Number" matInput [formControl]="finalInvoiced"
             i18n-placeholder>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="submit" mat-button (click)="closeDialog()" [disabled]="!isValid" i18n>Submit</button>
  </mat-dialog-actions>
  `
})
export class ArchiveProjectDialogComponent implements OnInit {

    public finalInvoiced: FormControl = new FormControl(0, Validators.required);

    constructor(
        public dialogRef: MatDialogRef<ContactPickerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Project
    ) {
    }

    get isValid() {
        return this.finalInvoiced.valid;
    }

    ngOnInit() {

        if (this.data.finalOfferOrder) {
            this.finalInvoiced.setValue(this.data.finalOfferOrder);
        }
    }

    closeDialog() {
        if (this.isValid) {
            this.dialogRef.close({
                finalInvoiced: this.finalInvoiced.value
            });
        }
    }

}
