<mat-table #table [dataSource]="budgetDataSource">

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Desc</mat-header-cell>
        <mat-cell *matCellDef="let element">
            {{ element.name }}&nbsp;
            <mat-icon *ngIf="element.hint" class="info-icon" matTooltip="{{element.hint}}">info</mat-icon>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cost">
        <mat-header-cell *matHeaderCellDef i18n>Cost</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.cost | number:'1.0-0' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="profit">
        <mat-header-cell *matHeaderCellDef i18n>Profit</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.profit | number:'1.0-0' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="expenses">
        <mat-header-cell *matHeaderCellDef i18n>Gen. Expenses</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.expenses | number:'1.0-0' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef i18n>Total</mat-header-cell>
        <mat-cell *matCellDef="let element">
            {{ element.total | currency:isExportBudget ? 'USD' : 'EUR':number:'1.0-0' }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalEuro">
        <mat-header-cell *matHeaderCellDef i18n>Total (EUR)</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.totalEuro | currency:'EUR':number:'1.0-0' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="taxes">
        <mat-header-cell *matHeaderCellDef i18n>Capital Improv. Tax</mat-header-cell>
        <mat-cell
                *matCellDef="let element">{{ element.taxes | currency:isExportBudget ? 'USD' : 'EUR':number:'1.0-0' }}
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
</mat-table>
