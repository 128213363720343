import {Budget} from '../interfaces';
import {AppUser} from '../../interfaces/app-user';



export class BudgetModel {

    static isEditable(budget: Budget, currentUser: AppUser) {
        if (['closed', 'archived', 'new'].indexOf(budget.status) !== -1) {
            return false;
        } else {
            return true;
        }
    }

}
