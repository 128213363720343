import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {DataService} from '../services/data.service';
import {Budget, SplittedCostTableElement} from '../budget/interfaces';
import {SummaryBalanceWrapper} from '../budget/interfaces/summaryBalance';



@Component({
    selector: 'app-summary-balance',
    templateUrl: './summary-balance.component.html',
    styleUrls: ['./summary-balance.component.scss']
})
export class SummaryBalanceComponent implements OnInit, OnDestroy {

    public budget$: Observable<Budget>;
    private _summaryBalance: ReplaySubject<SummaryBalanceWrapper> = new ReplaySubject(1);
    private unsubscribe$ = new Subject();

    constructor(private dataService: DataService, private route: ActivatedRoute) {
    }

    private _budgetId: string;

    get budgetId() {
        return this._budgetId;
    }

    get summary$() {
        return this._summaryBalance.asObservable();
    }

    ngOnInit() {

        this.budget$ = this.route.paramMap.pipe(
            switchMap((params) => {
                const budgetId = params.get('budgetId');
                return this.dataService.getSimpleBudget(budgetId);
            }),
            takeUntil(this.unsubscribe$));

        this.route.paramMap.pipe(
            switchMap((params) => {
                this._budgetId = params.get('budgetId');
                return this.dataService.getBudgetSummaryBalance(this._budgetId);
            }))
            .pipe(takeUntil(this.unsubscribe$), tap(s => console.log(s)))
            .subscribe(this._summaryBalance);
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    compare(a: number, b: number) {
        return a > b;
    }

    getGenExpProfitDiff(summary) {
        return Math.abs(summary.costSummary.profit.final - summary.costSummary.profit.budget);
    }

}
