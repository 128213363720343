import {BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject} from 'rxjs';

import {debounceTime, distinctUntilChanged, filter, first, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {AppStateService} from '../services/app-state.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {DataService} from '../services/data.service';

import {Budget, ISubBudgetTotals, Offer, OfferTemplate, SplittedCostTableElement, SubBudget} from './interfaces';
import {SubBudgetModel} from './models/sub-budget';
import {MatDialog} from '@angular/material/dialog';
import {AclUser} from '../interfaces/app-user';
import {BudgetModel} from './models/budget-model';
import {AuthService} from '../services/auth.service';
import {ConfigService} from '../services/config.service';
import * as moment from 'moment-timezone';
import {budgetSummary} from '@wondersys/wonderbudget-lib';
import {OpenProjectDialogComponent} from './open-project-dialog/open-project-dialog.component';
import {ChangeFinalofferDialogComponent} from './change-finaloffer-dialog/change-finaloffer-dialog.component';
import {DocumentEditorDialogComponent} from './doc-editor-dialog/doc-editor-dialog.component';
import {ArchiveProjectDialogComponent} from './archive-project-dialog/archive-project-dialog.component';
import {ContactPickerDialogComponent} from './contact-picker-dialog/contact-picker-dialog.component';
import {MatTabGroup} from '@angular/material/tabs';
import {SummaryBalanceWrapper} from './interfaces/summaryBalance';



@Component({
    selector: 'app-budget-detail',
    styleUrls: ['./budget-detail.component.css'],
    templateUrl: './budget-detail.component.html'
})
export class BudgetDetailComponent implements OnInit, OnDestroy {

    public budget: Budget;
    public exportBudget: Budget;  // BUDGET DELLA COMMESSA USA COLLEGATA (SE ESISTE)
    public importBudget: Budget; // BUDGET DELLA COMMESSA ITA COLLEGATA (SE EXPORT)
    public bId: string;
    public sbColl: Observable<Partial<SubBudget>[]>;
    public budgetFolderLink;
    public sbCollExport: Observable<Partial<SubBudget>[]>;
    public sbCollNameMap = new Map<String, String>();
    public subBudgetValuesForChart;
    public offerUrl;
    public poCreating = false;
    public refreshingCostSummary = false;
    public isEditable = true;
    public importingBoM = false;
    public removeExportLinkBtn = false;
    public createExportBudgetBtn = false;
    public tagFilter: string[] = [];
    public tags$;
    // SUBBUDGET RESUME
    public subBudgetSummary$;
    public resume;
    // SUMMARIES IN THIS PAGE
    public splittedCostsTableDataIta: SplittedCostTableElement[] = [];
    public splittedCostsTableDataExport: SplittedCostTableElement[] = [];
    // Permission flags
    public canChangeBudgetStatus: boolean;
    public canAddSubBudget: boolean;
    public canAskProjectAuth: boolean;
    public canGrantProjectAuth: boolean;
    public canModifyProject: boolean;
    public canDeleteBudget: boolean;
    public canPrepareOffer: boolean;
    // Offer templates
    public offerTemplates = [];
    public offerExportTemplates = [];
    public cosmoEnabled: boolean;
    public selectedTab = 0;
    graphObs: Observable<Map<String, ISubBudgetTotals>>;
    public costsTablesColumns = ['label', 'value', 'description'];
    public costsTableData = [];
    public costsTableDataExport = [];
    public showExport = true;
    @ViewChild('subBudgetsTabs', {static: false}) subBudgetsTabs: MatTabGroup;
    private unsubscribe$ = new Subject();
    // SUBJECT TO DEBOUNCE SUBBUDGET CHANGE
    private subBudgetValues = new BehaviorSubject(new Map<String, ISubBudgetTotals>());
    private activeSubBudgets = [];
    private globalSettings;

    private balanceSummary: SummaryBalanceWrapper;
    private balanceExportSummary: SummaryBalanceWrapper;

    constructor(
        private router: Router,
        private dataService: DataService,
        private configService: ConfigService,
        private auth: AuthService,
        public appState: AppStateService,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        public gDocDialog: MatDialog) {
    }

    /**** Permission Flags */
    private _canAssignBudget;

    get canAssignBudget(): boolean {
        return this._canAssignBudget;
    }

    private _canSeeFigures;

    get canSeeFigures(): boolean {
        return this._canSeeFigures;
    }

    private _canOpenProject;

    get canOpenProject(): boolean {
        return this._canOpenProject;
    }

    private _canReOpenProject;

    get canReOpenProject(): boolean {
        return this._canReOpenProject;
    }

    private _canArchiveProject;

    get canArchiveProject(): boolean {
        return this._canArchiveProject;
    }

    get canReceiveReport(): boolean {
        return this.auth.currentUser.type.includes('admin') || this.auth.currentUser.type.includes('finmgr');
    }

    private resetVariables() {
        this.budget = null;
        this.resume = null;
        this.exportBudget = null;
        this.importBudget = null;
        this.canAddSubBudget = false;
        this.createExportBudgetBtn = false;
        this.removeExportLinkBtn = false;
        this.splittedCostsTableDataIta = [];
        this.splittedCostsTableDataExport = [];
        this.costsTableData = [];
    }

    public ngOnInit() {

        const getBudget$ = this.route.paramMap.pipe(
            takeUntil(this.unsubscribe$),
            switchMap((params: ParamMap) => {
                this.bId = params.get('id');
                console.log('Reset. Getting budget with id:', this.bId);
                this.resetVariables();
                return this.dataService.getBudget(this.bId);
            }));

        const balanceSummary$ = this.route.paramMap.pipe(
            switchMap((params: ParamMap) =>
                this.dataService.getBudgetSummaryBalance(this.bId)
            ));


        combineLatest([getBudget$, balanceSummary$])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([b, summary]) => {
                if (!b) {
                    this.appState.showError({errorCode: -1, errorMessage: 'Unable to find this budget id'});
                    this.router.navigate(['/budget']);
                    return;
                }

                this.balanceSummary = summary;
                console.log('RELOADED CURRENT BUDGET:', b);

                this.budget = b;
                this.updateFiltersAndPermissions(this.budget);
                this.updatePaymentStatus(this.budget);
                this.getBudgetResume(this.bId);
                if (!b.export) {
                    this.splittedCostsTableDataIta = b.splittedCostSummary;
                }

                if (b.export) {
                    // The current is an export budget: get the import
                    this.splittedCostsTableDataExport = b.splittedCostSummary;
                    this.retrieveRelativeImport(b);

                } else if (b.exportBudget) {
                    // The current is an import budget: get the export
                    this.createExportBudgetBtn = false;
                    this.retrieveRelativeExport(this.budget);
                } else if (b.project && b.project.internal) {
                    // do not allow to generate export for internal project
                    this.createExportBudgetBtn = false;
                    this.removeExportLinkBtn = false;
                } else if (b.status && b.status === 'archived') {
                    // do not allow to generate export for archived projects
                    this.createExportBudgetBtn = false;
                    this.removeExportLinkBtn = false;
                } else {
                    this.createExportBudgetBtn = true;
                    this.removeExportLinkBtn = false;
                }

                this.updateCostsTables();

            }, (err) => {
                console.log('ERROR GETTING BUDGET', err.message);
            });

        this.auth.userHasPermissions(['app.budget.assign', 'app.budget.project.open',
            'app.budget.figures.read', 'app.budget.write', 'app.iproject.write', 'app.budget.auth.ask',
            'app.budget.auth.grant', 'app.budget.delete', 'app.prepare.offer'])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                this._canAssignBudget = val[0];
                this.canChangeBudgetStatus = val[0];
                this._canOpenProject = val[1];
                this._canReOpenProject = val[1];
                this._canArchiveProject = val[1];
                this._canSeeFigures = val[2];
                this.canAskProjectAuth = val[5];
                this.canGrantProjectAuth = val[6];
                this.canAddSubBudget = (val[3] || val[4]);
                this.canDeleteBudget = (val[7]);
                this.canPrepareOffer = (val[8]);
            });

        this.tags$ = this.route.paramMap.pipe(
            switchMap((params: ParamMap) =>
                this.dataService.getSubBudgetCollection(this.bId)))
            .pipe(takeUntil(this.unsubscribe$))
            .pipe(map((subs: SubBudget[]) => {
                const tags = [];

                subs.forEach(sb => {
                    if (sb.tags) {
                        sb.tags.forEach(t => {
                            if (tags.indexOf(t) === -1) {
                                tags.push(t);
                            }
                        });

                    }
                });

                this.tagFilter = this.tagFilter.filter(t => (tags.indexOf(t) > -1));

                return tags;

            }));

        this.sbColl = this.route.paramMap.pipe(
            switchMap((params: ParamMap) => this.dataService.getSubBudgetCollection(this.bId)),
            tap((sbc: SubBudget[]) => {
                console.log('SUB BUDGET coll', sbc);
                sbc.forEach(sb => {
                    this.sbCollNameMap.set(sb.id, sb.description);
                });
            }),
            map(sbc => {
                const sbcF = sbc.filter(sb => sb.deleted === undefined || sb.deleted === false || sb.deleted === null);

                this.activeSubBudgets = sbcF;

                return sbcF.map((sb) => ({
                    id: sb.id,
                    report: sb.report,
                    overrideEdit: sb.overrideEdit,
                    description: sb.description,
                    tags: sb.tags,
                    calculate: sb.calculate
                }));
            }),
            distinctUntilChanged((sbcEasy1, sbcEasy2) => {

                if (sbcEasy1.length !== sbcEasy2.length) {
                    return false;
                }
                for (const idx in sbcEasy1) {
                    if (sbcEasy1[idx].id !== sbcEasy2[idx].id ||
                        sbcEasy1[idx].calculate !== sbcEasy2[idx].calculate ||
                        sbcEasy1[idx].report !== sbcEasy2[idx].report ||
                        sbcEasy1[idx].description !== sbcEasy2[idx].description ||
                        sbcEasy1[idx].overrideEdit !== sbcEasy2[idx].overrideEdit ||
                        ((sbcEasy1[idx].tags && sbcEasy2[idx].tags) && (sbcEasy1[idx].tags.length !== sbcEasy2[idx].tags.length))) {
                        return false;
                    }
                }
                return true;
            }));

        this.configService.settings.subscribe((sett) => {
            if (sett) {
                this.globalSettings = sett.value;
                try {
                    this.offerTemplates = sett.value.googleDrive.offerTemplates;
                    this.offerExportTemplates = sett.value.googleDrive.offerExportTemplates;
                    this.cosmoEnabled = sett.value.cosmo.enabled;
                } catch (err) {
                    //
                }
            }
        });

        this.graphObs = this.updateGraph().pipe(takeUntil(this.unsubscribe$));
        this.graphObs.pipe(debounceTime(2000))
            .subscribe(() => {
                console.log('fired graphic update.');
                // DO not remove, otherwise graphic is not updated. Obs need at least 1 subscription to work
            });
    }

    public retrieveRelativeImport(b: Budget) {
        this.dataService.getBudget(b.export.from.id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((bITA) => {
                this.importBudget = null;
                if (bITA) {
                    if (this.budget.id !== bITA.id) {
                        this.importBudget = bITA;
                        console.log('Retrieved Budget ITA: ', this.importBudget);
                        this.splittedCostsTableDataIta = this.importBudget.splittedCostSummary;
                    }
                }
            });
    }

    public retrieveRelativeExport(b: Budget) {

        this.dataService.getBudgetByRef(b.exportBudget)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((bUSARef: any) => {
                this.exportBudget = null;
                if (bUSARef.payload.exists) {
                    if (this.budget.id !== bUSARef.payload.id) {
                        this.exportBudget = {...bUSARef.payload.data(), id: bUSARef.payload.id};
                        console.log('Retrieved relative Budget USA: ', this.exportBudget);
                        this.splittedCostsTableDataExport = this.exportBudget.splittedCostSummary;

                        this.sbCollExport = this.dataService
                            .getSubBudgetCollection(this.exportBudget.id)
                            .pipe(takeUntil(this.unsubscribe$));

                        this.dataService.getBudgetSummaryBalance(this.exportBudget.id)
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(result => {
                                this.balanceExportSummary = result;
                                this.updateCostsTables();
                            });

                        this.updateCostsTables();
                    }
                } else {

                    this.appState.showError({
                        errorCode: -1,
                        errorMessage: 'The linked export budget does not exist anymore'
                    });
                    this.removeExportLinkBtn = true;
                }
            });
    }

    public getBudgetResume(budgetId: string) {
        this.subBudgetSummary$ = this.dataService.getSubBudgetResume(budgetId)
            .pipe(takeUntil(this.unsubscribe$)).pipe(
                filter((summary) => summary !== undefined),
                map((resume: budgetSummary) => {
                    console.log('Retrieved this budget resume: ', resume);
                    this.resume = resume;
                    return resume;
                }));
    }

    public updateFiltersAndPermissions(b: Budget) {
        this.tagFilter = b.filter ? b.filter : [];
        this.offerUrl = b.offerUrl;
        this.budgetFolderLink = 'https://drive.google.com/drive/folders/' + b.driveFolderId;
        this.isEditable = BudgetModel.isEditable(b, this.auth.currentUser);
        this.canModifyProject = b.authorizedModify === 'yes' && b.status === 'closed';
        if (b.status === 'closed') {
            this.canAddSubBudget = this.canAskProjectAuth && this.canModifyProject;
        }
        console.log('updated page permissions...', this.canModifyProject, this.canAddSubBudget);
    }

    public updatePaymentStatus(b: Budget) {
        // triggers update of payment status from BE
        if (b.project && b.prosperworks && !b.project.internal && this.globalSettings.cosmo.enabled) {
            this.dataService.getBudgetPaymentStatus(
                b.id,
                b.project.projectNr, b.project.finalOfferOrder,
                this.globalSettings.prosperworks.whDomain,
                b.prosperworks.company_id)
                .pipe(take(1)).subscribe();
        }
    }

    public filterChange() {
        console.log('filterchange', this.tagFilter);

        this.dataService.patchBudget(this.budget.id, {
            filter: this.tagFilter
        }).then(() => {
            return this.activeSubBudgets.forEach(sb => {
                this.dataService.updateSubBudgetField(this.budget.id, sb.id, {
                    calculate: this.checkFilters(sb.tags)
                }).then(data => {
                    console.log('updating this sb', sb, this.checkFilters(sb.tags));

                    const sbValMap = this.subBudgetValues.value;
                    const sbVal = sbValMap.get(sb.id);
                    sbVal.calculate = this.checkFilters(sb.tags);
                    sbValMap.set(sb.id, sbVal);
                    this.subBudgetValues.next(sbValMap);

                });
            });
        });


    }

    public toggleCalculate(event, sb) {
        console.log(sb, event, this.checkFilters(event));
        this.dataService.updateSubBudgetField(this.budget.id, sb.id, {

            calculate: this.checkFilters(event)
        });
    }

    public checkFilters(tags) {
        if (tags) {
            return this.tagFilter.length === 0 || tags.some(tag => (this.tagFilter.indexOf(tag) > -1));
        } else {
            return this.tagFilter.length === 0;
        }

    }

    public updateGraph() {
        return this.subBudgetValues.pipe(
            tap((val) => {
                const ret = [];
                val.forEach((value: ISubBudgetTotals, key) => {

                    if (value.calculate) {
                        ret.push({
                            name: this.sbCollNameMap.get(key),
                            series: [
                                {
                                    name: 'Activities',
                                    value: value.calculate ? value.activities : 0
                                },
                                {
                                    name: 'Materials',
                                    value: value.calculate ? value.materials : 0
                                }
                            ]
                        });
                    }

                });
                this.subBudgetValuesForChart = ret;
            }));
    }

    public removeExportLink() {
        this.dataService.patchBudget(this.bId, {
            exportBudget: null
        });
    }

    public openOfferWizard(): void {
        // console.log("global settings", this.globalSettings)
        this.gDocDialog.open(DocumentEditorDialogComponent, {
            width: '500px',
            data: {
                templates: this.globalSettings.googleDrive.offerTemplates,
                budgetFolder: this.budget.driveFolderId,
                imageFolderPath: this.globalSettings.googleDrive.imageFolderPath
            }
        }).afterClosed().subscribe(data => {
            if (data) {
                this.prepareOfferWithTemplate(data.imageList, data.productSelected.templateId, null, null);
            }

        });
    }

    public openAssignDialog(): void {
        // Get the list of users
        this.dataService.getAppUsersOnce().pipe(map((usersDocs) => {
            return usersDocs.docs.map(doc => {
                const data = doc.data() as AclUser;
                const id = doc.id;
                return {id, ...data};
            });
        })).subscribe(d => {
            console.log('Users data', d);
            const dialogRef = this.dialog.open(ContactPickerDialogComponent, {
                width: '350px',
                data: {users: d}
            });

            dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed ', result);
                if (!result) {
                    return;
                }
                this.dataService.assignBudget(
                    result.user,
                    this.bId,
                    result.deliveryThr = moment(result.deliveryThr).toDate(),
                    result.noteToPrjMgr
                )
                    .subscribe(data => {
                        console.log('Update resutl', data);
                    });
            });
        });
    }

    public reopenAssignDialog(): void {
        // Get the list of users
        this.dataService.getAppUsersOnce().pipe(map((usersDocs) => {
            return usersDocs.docs.map(doc => {
                const data = doc.data() as AclUser;
                const id = doc.id;
                return {id, ...data};
            });
        })).subscribe(d => {
            console.log('Users data', d);
            const dialogRef = this.dialog.open(ContactPickerDialogComponent, {
                width: '350px',
                data: {users: d, showOnlyUser: true}
            });

            dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed ', result);
                if (!result) {
                    return;
                }

                this.dataService.reassignBudget(
                    result.user,
                    this.bId
                )
                    .subscribe(data => {
                        console.log('Update resutl', data);
                    });
            });
        });
    }

    public createExportBudget() {
        if (this.budget.exportBudget) {
            console.log('This budget already has a linked export budget');
            return;
        }
        this.dataService.createExportBudget(this.budget);
    }

    public prepareExportOffer(offerTemplate?: OfferTemplate) {
        return this.prepareOffer(offerTemplate, this.exportBudget);
    }

    public prepareOffer(offerTemplate?: OfferTemplate, eBudget?: Budget) {

        this.poCreating = true;

        // Update Offer Values
        let locale = 'it-IT';
        let budget;
        if (eBudget) {
            eBudget.offer = this.offerDataExport(this.splittedCostsTableDataExport);
            budget = <any>eBudget;
            locale = 'en-US';
        } else {
            this.budget.offer = this.offerData(this.splittedCostsTableDataIta);
            budget = <any>this.budget;
        }

        console.log('budget per offer', budget);

        let o1;
        if (budget.prosperworks && budget.prosperworks.company_id && budget.prosperworks.primary_contact_id) {
            o1 = forkJoin([
                this.dataService.getCompanyById(budget.prosperworks.company_id),
                this.dataService.getPersonById(budget.prosperworks.primary_contact_id)
            ]);
        } else {
            o1 = of([null, null]);
        }
        // Retrieve company data
        o1.pipe(switchMap((aaa) => {
            console.log('company & primary: ', aaa);
            const company = aaa[0];
            const primary = aaa[1];

            let offerData;
            if (!budget.prosperworks) {
                offerData = {
                    company: budget.client,
                    project: budget.name,
                    subject: budget.description
                };
            } else {
                const pw = budget.prosperworks;
                offerData = {
                    company: pw.company_name,
                    project: pw.name,
                    subject: pw.details
                };

                if (company) {
                    offerData.address = company.address ? company.address.street : '';
                    offerData.zip_code = company.address ? company.address.postal_code : '';
                    offerData.city = company.address ? company.address.city : '';
                    offerData.country = company.address ? company.address.country : '';
                }

                if (primary) {
                    offerData.primary_contact = primary.name;
                }


                if (eBudget) {
                    offerData.date = moment.tz(new Date(), 'Europe/Rome').format('MM/DD/YYYY');
                } else {
                    offerData.date = moment.tz(new Date(), 'Europe/Rome').format('DD/MM/YYYY');
                }

                offerData.BU_number = budget.budgetNr;
                offerData.chapter_3 = 'Offerta Economica';
                offerData.chapter_3_item_1 = 'Produzione';

                offerData.section_1_title = 'Produzione';
                // offerData.section_2_title = 'Progettazione';
                // offerData.section_3_title = 'Trasporto';
                // offerData.section_4_title = 'Montaggio';

                try {
                    console.log('Found template ', offerTemplate);
                    offerData.idModello = offerTemplate.templateId;
                    locale = offerTemplate.locale ? offerTemplate.locale : locale;
                    if (!offerData.idModello) {
                        throw new Error('Template not found for ' + offerTemplate);
                    }
                    console.log('Using template ', offerData.idModello);
                } catch (err) {
                    console.log('Using default template because of', err);
                    offerData.idModello = '1H5Ip_j_egwbLoYwO-fGIUOOr_32eoLwApt40sLFBj5o';
                }

                const printNumber = (n: number) => {
                    try {
                        if (n) {
                            return n.toLocaleString(locale, {
                                maximumFractionDigits: 2
                            });
                        } else {
                            return '';
                        }
                    } catch (err) {
                        console.warn('Cannot convert value to string', n);
                        return '';
                    }
                };

                offerData.section_1_amount = printNumber(budget.offer.productionAmount);
                offerData.section_2_amount = printNumber(budget.offer.designAmount);
                offerData.section_3_amount = printNumber(budget.offer.shippingAmount);
                offerData.section_4_amount = printNumber(budget.offer.assemblyAmount);

                offerData.total_amount = printNumber(budget.offer.offerAmount);
                offerData.drawings_weeks = 3;
                offerData.approval_weeks = 4;
                offerData.production_weeks = 10;

                offerData.subFolderName = 'Documenti';

                const driveFolderId = this.budget.driveFolderId ? this.budget.driveFolderId : '1O42HTaUnFElDR92sljnBKH6kAAxlSIdX';
                // TODO id driveFolderId is not defined skip to a defult value
                console.log('Direv folder id', driveFolderId);

                offerData.idCartellaDestinazione = '0AD2PgtZoxw7xUk9PVA';
                offerData.nomeDocumento = budget.budgetNr + '-' + pw.name;

                console.log('offerData', offerData);

            }
            return this.dataService.prepareOffer(offerData);
        })).subscribe((d) => {
                console.log('ret', d);
                this.poCreating = false;

                if (!d.response || !d.response.result || d.response.result.returnCode || !d.response.result.documentUrl) {
                    return this.appState.showError({
                        errorCode: (d.response && d.response.result) ? d.response.result.returnCode : -1,
                        errorMessage: 'The offer was not created',
                        errorSource: d.response
                    });
                }

                this.dataService.patchBudget(budget.id, {offerUrl: String(d.response.result.documentUrl)})
                    .then(() => {
                        console.log('Budget updated');
                    });
            }, (err) => {
                this.poCreating = false;
                console.log(err);
            }, () => {
                this.poCreating = false;
            }
        );
    }

    public prepareOfferWithTemplate(images, templateId, offerTemplate?: OfferTemplate, eBudget?: Budget) {

        this.poCreating = true;

        // Update Offer Values
        let locale = 'it-IT';
        let budget;
        if (eBudget) {
            eBudget.offer = this.offerDataExport(this.splittedCostsTableDataExport);
            budget = <any>eBudget;
            locale = 'en-US';
        } else {
            this.budget.offer = this.offerData(this.splittedCostsTableDataIta);
            budget = <any>this.budget;
        }

        console.log('budget per offer', budget);

        let o1;
        if (budget.prosperworks && budget.prosperworks.company_id && budget.prosperworks.primary_contact_id) {
            o1 = forkJoin([
                this.dataService.getCompanyById(budget.prosperworks.company_id),
                this.dataService.getPersonById(budget.prosperworks.primary_contact_id)
            ]);
        } else {
            o1 = of([null, null]);
        }
        // Retrieve company data
        o1.pipe(switchMap((aaa) => {
            console.log('aaa', aaa);
            const company = aaa[0];
            const primary = aaa[1];

            let offerData;
            if (!budget.prosperworks) {
                offerData = {
                    company: budget.client,
                    project: budget.name,
                    subject: budget.description
                };
            } else {
                const pw = budget.prosperworks;
                offerData = {
                    company: pw.company_name,
                    project: pw.name,
                    subject: pw.details
                };

                if (company) {
                    offerData.address = company.address ? company.address.street : '';
                    offerData.zip_code = company.address ? company.address.postal_code : '';
                    offerData.city = company.address ? company.address.city : '';
                    offerData.country = company.address ? company.address.country : '';
                }

                if (primary) {
                    offerData.primary_contact = primary.name;
                }


                if (eBudget) {
                    offerData.date = moment.tz(new Date(), 'Europe/Rome').format('MM/DD/YYYY');
                } else {
                    offerData.date = moment.tz(new Date(), 'Europe/Rome').format('DD/MM/YYYY');
                }

                offerData.BU_number = budget.budgetNr;
                offerData.chapter_3 = 'Offerta Economica';
                offerData.chapter_3_item_1 = 'Produzione';

                offerData.section_1_title = 'Produzione';
                // offerData.section_2_title = 'Progettazione';
                // offerData.section_3_title = 'Trasporto';
                // offerData.section_4_title = 'Montaggio';

                try {
                    console.log('Found template ', offerTemplate);
                    offerData.idModello = offerTemplate.templateId;
                    locale = offerTemplate.locale ? offerTemplate.locale : locale;
                    if (!offerData.idModello) {
                        throw new Error('Template not found for ' + offerTemplate);
                    }
                    console.log('Using template ', offerData.idModello);
                } catch (err) {
                    console.log('Using default template because of', err);
                    offerData.idModello = '1H5Ip_j_egwbLoYwO-fGIUOOr_32eoLwApt40sLFBj5o';
                }

                const printNumber = (n: number) => {
                    try {
                        if (n) {
                            return n.toLocaleString(locale, {
                                maximumFractionDigits: 2
                            });
                        } else {
                            return '';
                        }
                    } catch (err) {
                        console.warn('Cannot convert value to string', n);
                        return '';
                    }
                };

                offerData.section_1_amount = printNumber(budget.offer.productionAmount);
                offerData.section_2_amount = printNumber(budget.offer.designAmount);
                offerData.section_3_amount = printNumber(budget.offer.shippingAmount);
                offerData.section_4_amount = printNumber(budget.offer.assemblyAmount);

                offerData.total_amount = printNumber(budget.offer.offerAmount);
                offerData.drawings_weeks = 3;
                offerData.approval_weeks = 4;
                offerData.production_weeks = 10;

                offerData.subFolderName = 'Documenti';

                // https://github.com/wondersys/wonderbudget-backend/issues/71
                const driveFolderId =
                    this.budget.offerDestinationFolderId ? this.budget.offerDestinationFolderId :
                        this.budget.driveFolderId ? this.budget.driveFolderId : '1O42HTaUnFElDR92sljnBKH6kAAxlSIdX';

                console.log('Dirve folder id', driveFolderId);

                offerData.idCartellaDestinazione = driveFolderId;
                offerData.nomeDocumento = budget.budgetNr + '-' + pw.name;

                console.log('offerData', offerData);

            }
            return this.dataService.generateOfferDocument(offerData, images, templateId);
        })).subscribe((d) => {
                console.log('created WS Offer document', d);
                this.poCreating = false;
                this.dataService.patchBudget(this.bId, {offerUrl: String(d.documentUri)})
                    .then(() => {
                        console.log('Budget updated');
                    });
            }, (err) => {
                this.poCreating = false;
                console.log(err);

            }
        );
    }

    public updateSubBudgetValues($event: ISubBudgetTotals, sbId) {
        const sbValMap = this.subBudgetValues.value;
        sbValMap.set(sbId, $event);
        this.subBudgetValues.next(sbValMap);
    }

    public deleteSubBudget(subBudgetId: string) {
        const sbValMap = this.subBudgetValues.value;
        console.log('deleteSubBudget - deleting subBudget', subBudgetId, sbValMap);

        if (sbValMap.delete(subBudgetId)) {
            console.log('deleteSubBudget - deleted');
        }
        this.subBudgetValues.next(sbValMap);
    }

    public deleteBudget() {
        this.appState.confirmDialog({confirmMessage: 'The current budget will be deleted.'})
            .then((result) => {

                if (result && result.confirm) {
                    this.dataService.deleteBudgetApi(this.auth.currentUser.email, this.bId).subscribe(res => {
                        this.appState.infoDialog({infoMessage: 'The budget has been successfully deleted'});
                        this.router.navigate(['/budget']);
                    }, err => {
                        console.log(err);
                    });
                }

            });

    }

    public importFromSheet() {
        console.log('IS BUDGET', this.budget);
        if (!this.budget) {
            return false;
        } else {
            this.importingBoM = true;
            return this.dataService.importDataFromSheet(this.budget.driveFolderId, this.budget.id)
                .pipe(take(1))
                .toPromise()
                .then((data) => {
                    if (data) {
                        console.log('Data from sheet 2 ', data);
                        this.importingBoM = false;
                    }
                })
                .catch((err) => {
                    console.log('Error ', err);
                    this.importingBoM = false;
                });
        }
    }

    /**
     * Updating ITA updates USA as well, and viceversa
     */
    public refreshCostSummary(budgetId: string) {
        this.refreshingCostSummary = true;
        console.log('refreshCostSummary - Refreshing budget.costSummary for budget: ', budgetId);

        this.dataService.runSummaryRecalculateJob(budgetId)
            .subscribe(() => {
                console.log('refreshCostSummary - Refresh over.', budgetId);
                this.refreshingCostSummary = false;
            }, err => {
                console.error(err);
                this.refreshingCostSummary = false;
            });
    }

    public openProject() {
        const isExport = !!this.budget.export;
        let projectNrITA = '';
        if (isExport && this.importBudget && this.importBudget.project) {
            projectNrITA = this.importBudget.project.projectNr;
        }
        const dialogRef = this.dialog.open(OpenProjectDialogComponent, {
            width: '350px',
            data: {
                budget: this.budget,
                user: this.budget.assignee,
                oData: this.offerData(this.splittedCostsTableDataIta),
                projectNrITA: projectNrITA
            }
        });

        dialogRef.afterClosed().pipe(
            filter(result => result))
            .subscribe(result => {
                console.log('The dialog was closed ', result);
                this.dataService.openProject(
                    this.bId,
                    result.projectNr,
                    result.finalOffer,
                    result.projectManager,
                    result.paymentMethod,
                    result.paymentMethodCode,
                    result.deliveryDate)
                    .subscribe(data => {
                        console.log('Updated result', data);
                    }, err => {
                        console.error(err);
                        this.appState.showError({
                            errorCode: -1,
                            errorMessage: 'Request has not been completed successfully, please try again in a few seconds'
                        });
                    });
            });
    }

    public reOpenProject() {
        this.appState.confirmDialog({confirmMessage: 'Budget will be re opened'})
            .then(async (result) => {
                if (result && result.confirm) {
                    // if it was closed, then back to closed. Assigned otherwise.
                    this.budget.status = this.budget.project ? 'closed' : 'assigned';
                    const data = await this.dataService.patchBudget(this.budget.id, {
                        status: this.budget.status,
                        reopened: {userId: this.auth.currentUser.email, date: new Date()}
                    });
                    console.log('this is data from reOpenProject:', data);
                }
            });
    }

    public getReportFile() {
        return this.dataService.getSimpleBudgetSummaryBalance(this.budget.id).then(summarySnap => {
            if (summarySnap.exists) {
                return this.dataService.createSummaryPdf(this.budget.id).pipe(first()).subscribe(data => {
                    console.log('Summary execution terminated: ', data);
                });
            } else {
                return this.dataService.createSummaryBalance(this.budget.id).pipe(
                    first())
                    .subscribe(() => {
                        this.dataService.createSummaryPdf(this.budget.id).pipe(first()).subscribe(result => {
                            console.log('pdf creation execution terminated: ', result);
                        });
                    });
            }
        });
    }

    public archive() {
        if (this.budget.project) {
            return this.archiveProject();
        } else {
            return this.archiveBudget();
        }
    }

    public addSubBudget(sb: SubBudgetModel) {
        console.log('I\'m the parent ', sb);
        this.dataService.addSubBudget(this.bId, sb.description, sb.generatedBy)
            .subscribe((r) => {
                // Navigate to the new tab
                console.log('Just created a subbudget: ', r);
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();

        this.subBudgetValues.complete();
    }

    public askBudgetModifyAuth() {
        console.log(`Asking for modify permission for budget:`, this.budget);
        this.dataService.askBudgetModifyAuth({
            project: this.budget.project,
            id: this.budget.id,
            budgetNr: this.budget.budgetNr,
            prosperworks: this.budget.prosperworks
        }).pipe(first())
            .subscribe((res) => {
                    console.log('Authorization asked', res);
                },
                (err) => {
                    console.warn('There was an error', err);
                });
    }

    public doneModifications() {
        console.log(`done modifications for this budget:`, this.budget);
        this.dataService.patchBudget(this.budget.id, {authorizedModify: null});
    }

    public ackBudgetModifyAuth(isAuth: boolean) {
        console.log(`ACK for modify permission for budget:`, this.budget);
        this.dataService.ackBudgetModifyAuth({
            project: this.budget.project,
            id: this.budget.id,
            budgetNr: this.budget.budgetNr,
            prosperworks: this.budget.prosperworks
        }, isAuth).pipe(first())
            .subscribe((res) => {
                    console.log('Authorization confirmed', res);
                },
                (err) => {
                    console.log('Error while authorizing order', err);
                });
    }

    public updateCostsTables() {
        console.log('updating costs tables', this.budget.id, this.budget.costSummary);
        if (!this.budget || !this.budget.costSummary) {
            return;
        }
        this.costsTableData = [
            {
                label: 'Total Cost ITA',
                value: this.budget.costSummary.total,
                description: 'Internal cost (activities + materials)',
                currency: 'EUR'
            },
            {
                label: 'Total Offer ITA',
                value: this.balanceSummary.costSummary.budget.totalOffer ? this.balanceSummary.costSummary.budget.totalOffer : '',
                description: 'Total cost ITA + margins ITA',
                currency: 'EUR'
            },
            {
                label: 'Final Offer ITA',
                value: this.budget.project ? this.budget.project.finalOfferOrder : '',
                description: 'Final contract value, manually specified',
                currency: 'EUR'
            }
        ];

        if (this.exportBudget && this.balanceExportSummary) {
            const totalOffer = this.balanceExportSummary.costSummary?.budget?.totalOffer;
            const totalCost = this.balanceExportSummary.costSummary?.budget?.totalCost;
            this.costsTableDataExport = [
                {
                    label: 'Total Cost USA',
                    valueCurrency: totalCost / this.exportBudget.export.exchangeRate,
                    value: this.balanceExportSummary.costSummary.budget.totalCost,
                    description: 'Materials USA (includes Total Offer ITA) + Activities USA',
                    currency: 'USD'
                },
                {
                    label: 'Total Offer USA',
                    value: totalOffer ? totalOffer : '',
                    description: 'Total cost USA + margins USA',
                    currency: 'USD'
                },
                {
                    label: 'Final Offer USA',
                    value: this.exportBudget.project ? this.exportBudget.project.finalOfferOrder : '',
                    description: 'Final contract value, manually specified',
                    currency: 'USD'
                }
            ];
        }
    }

    applyShowExport(checked: boolean) {
        this.showExport = checked;
    }

    changeFinalOffer(isExport: boolean) {

        const finalOfferOrder = isExport ?
            this.exportBudget.project.finalOfferOrder :
            this.budget.project.finalOfferOrder;
        const finalOfferOrderChanges = isExport ?
            this.exportBudget.project.finalOfferOrderChanges :
            this.budget.project.finalOfferOrderChanges;
        const dialogRef = this.dialog.open(ChangeFinalofferDialogComponent, {
            width: '450px',
            data: {finalOfferOrder, finalOfferOrderChanges, isExport}
        });

        dialogRef.afterClosed().pipe(
            filter(result => result))
            .subscribe(result => {
                console.log('closed changeFinalOffer: ', result);

                const updateObj = isExport ? this.exportBudget.project : this.budget.project;
                const previousValue = updateObj.finalOfferOrder !== undefined ? updateObj.finalOfferOrder : 0;
                const audit = {
                    user: this.auth.currentUser.displayName,
                    date: new Date(),
                    prevValue: previousValue,
                    newValue: result.finalOfferOrder,
                    // simply round to 2 decimals
                    difference: Math.round((result.finalOfferOrder - previousValue + Number.EPSILON) * 100) / 100
                };
                updateObj.finalOfferOrder = result.finalOfferOrder;
                if (updateObj.finalOfferOrderChanges) {
                    updateObj.finalOfferOrderChanges.push(audit);
                } else {
                    updateObj.finalOfferOrderChanges = [audit];
                }
                const budgetInfo = {
                    export: isExport ? this.exportBudget.export : this.budget.export,
                    projectNr: isExport ? this.exportBudget.project.projectNr : this.budget.project.projectNr,
                    id: isExport ? this.exportBudget.id : this.budget.id
                };

                this.dataService.changeFinalOffer(budgetInfo, updateObj, audit, this.auth.currentUser).pipe(take(1)).subscribe(res => {

                }, err => {
                    console.log(err);
                    this.appState.showError({errorCode: -1, errorMessage: 'Failed to update the values or send email'});
                });
            });
    }

    changeTab(label: string) {
        if (this.subBudgetsTabs) {
            const tabs = (this.subBudgetsTabs?._tabs as any)._results,
                match = tabs?.find(x => x.textLabel?.toUpperCase() === label.toUpperCase());

            if (match) {
                this.subBudgetsTabs.selectedIndex = match.position + 1;
            }
        }
    }

    private offerData(splittedCostsTableData: any[]): Offer {
        const oData: Offer = <Offer>{}; // we are lying here, but it's for compatibility with past...
        splittedCostsTableData.forEach((el: SplittedCostTableElement) => {
            switch (el.id) {
                case 'assembly':
                    oData['assemblyAmount'] = el.total;
                    break;
                case 'design':
                    oData['designAmount'] = el.total;
                    break;
                case 'production':
                    oData['productionAmount'] = el.total;
                    break;
                case 'shipping':
                    oData['shippingAmount'] = el.total;
                    break;
                case 'total':
                    oData['offerAmount'] = el.total;
                    break;
            }
        });
        return oData;
    }

    private offerDataExport(splittedCostsTableDataExport: any[]): Offer {
        const oData: Offer = <Offer>{}; // we are lying here but it's for compatibility with past..
        splittedCostsTableDataExport.forEach((el: SplittedCostTableElement) => {
            switch (el.id) {
                case 'assembly': // installation
                    oData['assemblyAmount'] = el.total;
                    break;
                case 'design':
                    oData['designAmount'] = el.total;
                    break;
                case 'production':
                    oData['productionAmount'] = el.total;
                    break;
                case 'shipping':
                    oData['shippingAmount'] = el.total;
                    break;
                case 'total':
                    oData['offerAmount'] = el.total;
                    break;
            }
        });
        return oData;
    }

    private archiveBudget() {
        this.appState.confirmDialog({confirmMessage: 'Budget will be archived'})
            .then((result) => {
                if (result && result.confirm) {
                    this.budget.archivedDate = new Date();
                    this.budget.status = 'archived';

                    return this.dataService.archiveBudget(this.budget.id, false)
                        .subscribe(res => {
                            console.log(res);
                            console.log('Budget archived');
                        });
                }
            });
    }

    private archiveProject() {

        const dialogRef = this.dialog.open(ArchiveProjectDialogComponent, {
            width: '350px',
            data: this.budget.project
        });

        return dialogRef.afterClosed().pipe(
            filter(result => result))
            .subscribe(result => {

                return this.dataService.archiveBudget(this.budget.id, true, result.finalInvoiced)
                    .subscribe(res => {
                        console.log(res);
                        console.log('Project archived');
                    });
            });
    }
}
