<div *ngIf="formUpdating" class="margin-form-overlay">
    <mat-spinner diameter=32></mat-spinner>
</div>
<form (ngSubmit)="submit()" [formGroup]="marginForm" class="margin-form-container">
    <mat-form-field>
        <input formControlName="generalExpenses" i18n-placeholder matInput placeholder="General Expenses" type="number">
        <span matSuffix>%</span>
    </mat-form-field>
    <div *ngIf="kind === 'USA'" class="row-start">
        <mat-form-field>
            <input formControlName="ciTaxesPercentage" i18n-placeholder matInput placeholder="Capital Improvement Taxes"
                   type="number">
            <span matSuffix>%</span>
        </mat-form-field>
        <mat-slide-toggle (change)="checkDisableCiTax($event.checked)" formControlName="capitalImprovement"
                          style="margin-left: 10px">
        </mat-slide-toggle>
    </div>
    <mat-form-field>
        <input formControlName="profit" i18n-placeholder matInput placeholder="Profit" type="number">
        <span matSuffix>%</span>
    </mat-form-field>
    <button (submit)="submit()" [disabled]="!marginForm.valid" mat-button type="submit">Save</button>
</form>
