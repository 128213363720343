import {BehaviorSubject, combineLatest, forkJoin, from, Observable, of} from 'rxjs';
import {catchError, filter, first, map, switchMap, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {QueryFn} from '@angular/fire/firestore/interfaces';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import * as firebase from 'firebase';


import {AuthService} from './auth.service';
import {ConfigService} from './config.service';

import * as moment from 'moment-timezone';
import {Role, User} from '../users/interfaces/users';
import {ActivitiesTemplate, Activity, Budget, GeneratedBy, Material, SubBudget} from '../budget/interfaces';
import {Settings} from '../interfaces/settings';
import {Storage} from '../interfaces/storage';
import {Timereport} from '../timereport/interfaces';
import {Invoice, Order, StatField, StatReport} from '@wondersys/wonderbudget-lib';
import {Ddt} from '../ddt/interfaces/ddt';
import {AclUser, AppUser, AppUserUtil} from '../interfaces/app-user';
import {Company} from '../interfaces/address';
import {PaymentMethods} from '../interfaces/payment-methods';
import {Sequence} from '../interfaces/sequence';
import {LantekNesting} from '../laser-cut/interfaces/lantekNesting';
import {SubBudgetModel} from '../budget/models/sub-budget';
import {calculateCustomerFromHostname} from '../util';
// Google API
import {GoogleApiService} from 'ng-gapi';
import {OrderLIstFilter} from './order-list-table.service';
import {InvoicesLIstFilter} from './invoices-list-table.service';
import {TimereportListFilter} from './timereport-list-table.service';
import {BudgetListFilter} from './budget-list-table.service';
import {DdtListFilter} from './ddt-list-table.service';
import {SummaryBalanceWrapper} from '../budget/interfaces/summaryBalance';



const TIMERREPORT_COLLECTION = 'TimeReportCollection';
const EMAILREPORT_COLLECTION = 'TimeReportEmail';
const STATREPORTS_COLLECTION = 'StatReportCollection';
const STATFIELDS_COLLECTION = 'StatFields';
const ORDER_COLLECTION = 'Order';
const INVOICES_COLLECTION = 'Invoices';
const DDT_COLLECTION = 'Ddt';
const SEQ_COLLECTION = 'Sequences';
const BUDGET_COLLECTION = 'BudgetCollection';
const SUMMARY_COLLECTION = 'SummaryCollection';
const STORAGE_COLLECTION = 'StorageCollection';
const SUBBUDGET_COLLECTION = 'sub-budgets';
const PAYMENTMETHODS_COLLECTION = 'PaymentMethods';
const USER_COLLECTION = 'Users';
const ROLE_COLLECTION = 'Roles';


@Injectable({providedIn: 'root'})
export class DataService {

    private brokenOrders = [];
    private allBrokenInvoices = [];

    private cDR: AngularFirestoreDocument<any>;
    private userCollectionRef: AngularFirestoreCollection<AclUser>;
    private activitiesTemplateCollRef: AngularFirestoreCollection<ActivitiesTemplate>;
    private activitiesTemplate$ = new BehaviorSubject<ActivitiesTemplate>(null);

    private settings: Settings;

    constructor(
        private afs: AngularFirestore,
        private auth: AuthService,
        private configService: ConfigService,
        private http: HttpClient, private googleApiService: GoogleApiService) {

        const CUSTOMER_DOMAIN = calculateCustomerFromHostname(document.location.hostname);
        console.log('Dataservice constructor. setting customerDocumentReference. Domain inferred from the URL: ', CUSTOMER_DOMAIN);
        this.cDR = this.afs.collection('Customers').doc(CUSTOMER_DOMAIN);

        this.userCollectionRef = this.cDR.collection<AclUser>('Users'); // a ref to the todos collection

        // Subscribe to Activity templates
        this.activitiesTemplateCollRef = this.cDR
            .collection<ActivitiesTemplate>('/Blueprints/ActivitiesTemplate/ActivitiesHistory',
                ref => ref.where('latest', '==', true));
        this.activitiesTemplateCollRef.valueChanges().pipe(
            map((aat) => aat[0]))
            // .do((aat) => console.log('aat', aat))
            .subscribe(this.activitiesTemplate$);

        // Subscrbe to settings
        this.configService.settings.subscribe((sett) => {
            this.settings = sett;
        });

        setTimeout(() => {
            gapi.load('client', () => {
                // await gapi.client.load('drive', 'v3');
                console.log('GAPI client loaded!');
            });
        }, 1200);
        console.log('end Dataservice constructor. ');
    }

    public getExchangeRate(rate) {
        // const url = 'https://api.fixer.io/latest?symbols=' + rate; vecchia API deprecata
        const url = 'http://data.fixer.io/api/latest?access_key=f697bd1cc8a4fc2f85d18b58266adaec&symbols=' + rate;
        // const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return this.http.get<any>(url).pipe(
            map((res) => {
                console.log(res);
                if (res && res.rates && rate in res.rates) {
                    return res.rates[rate];
                } else {
                    console.error(res);
                }
            }));
    }

    public getExchangeRateInternal(rate) {

        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                const sett = this.configService.settings.value;
                const endPoint = sett.value.firebaseApi + '/budget/exchangeRate/' + rate;
                // console.log('Got tid', tid);
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                // headers.append('Authorization', `Bearer ${tid}`);
                // headers.append('X-App-Domain', this.auth.appDomain);
                if (tid) {
                    return this.http.get(endPoint, {
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public checkIfExists(url) {
        this.http.head(url).pipe(
            map((res) => {
                return true;
            }),
            catchError((err) => {
                return of(false);
            }));
    }

    public prepareOffer(opt: object): Observable<any> {

        // ID of the script to call. Acquire this from the Apps Script editor,
        // under Publish > Deploy as API executable.
        // https://script.google.com/a/wonder-sys.com/d/1RwgJdXrda8b0eesunc4A1Cu-AQMZYwYbndh_fVsWPmHJbZMdc2Yi3lJ9/edit?usp=drive_web
        const scriptId = 'MCmb5gDLxuOVSIpBmrvQB94XfdhbRAC0r';

        return this.googleApiService.onLoad().pipe(switchMap(() => {
            console.log('Gapi ready', gapi);
            return this.callScriptFunction(gapi, scriptId, 'compilaOfferta', opt);
            // return Observable.of(this.callScriptFunction(gapi, opt));
        }));
    }

    public prepareOrderPdf(orderId): Observable<any> {
        const param = {orderId: orderId, domainId: this.auth.appDomain};
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                const sett = this.configService.settings.value;
                const endPoint = sett.value.firebaseApi + '/order/createpdf';
                // console.log('Got tid', tid);
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                // headers.append('Authorization', `Bearer ${tid}`);
                // headers.append('X-App-Domain', this.auth.appDomain);
                if (tid) {
                    return this.http.post(endPoint, param, {
                        // responseType: 'json',
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }


    public createOfferDocument(productName, imageList): Observable<any> {
        console.log('createOfferDocument...');
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {

                if (tid) {
                    console.log(tid);
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain

                    });
                    const sett = this.configService.settings.value;
                    return this.http.post(sett.value.firebaseApi + '/offer/generateDoc/', {
                        images: imageList,
                        productName: productName

                    }, {headers: headers});

                } else {
                    throw Error('No token');
                }
            }));


    }

    public generateOfferDocument(offerData, images, templateId): Observable<any> {
        console.log('service call is ok');

        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {

                if (tid) {
                    console.log(tid);
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain

                    });

                    const sett = this.configService.settings.value;

                    return this.http.post(sett.value.firebaseApi + '/offer/generateOfferDocument/', {
                        offerData: offerData,
                        images: images,
                        templateId: templateId
                    }, {headers: headers});

                } else {
                    throw Error('No token');
                }
            }));
    }


    public askOrderAuth(order): Observable<any> {
        const param = {order: order};
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                const sett = this.configService.settings.value;
                const endPoint = sett.value.firebaseApi + '/order/askOrderAuth';
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                if (tid) {
                    return this.http.post(endPoint, param, {
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public ackOrderAuth(order, isAuth): Observable<any> {
        const param = {order: order, isAuth: isAuth};
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                const sett = this.configService.settings.value;
                const endPoint = sett.value.firebaseApi + '/order/ackOrderAuth';
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                if (tid) {
                    return this.http.post(endPoint, param, {
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public pushDocToPw(opportunityId, docUrl) {
        return forkJoin([
            this.auth.getUserIdToken().pipe(first()),
            this.configService.settings.pipe(filter((value) => value != null), first())
        ]).pipe(
            first(),
            switchMap(([tid, sett]) => {
                const endPoint = sett.value.prosperworks.endpoint;
                const headers = {
                    'X-App-Domain': this.auth.appDomain,
                    'Authorization': `Bearer ${tid}`,
                    ...sett.value.prosperworks.headers // This is here only for backward compatibility
                };

                return this.http.put(endPoint + opportunityId, {
                    headers: new HttpHeaders(headers)
                });
            }));
    }

    // This could fail, settings can be null
    public getItemCategories = () => {
        return this.configService.settings.pipe(
            map((s) => s.value.categories));
    }

    public testApi = () => {
        const endPoint = this.settings.value.firebaseApi + '/budget';
        this.auth.getUserIdToken().pipe(map((tid) => {
            console.log('Got tid', tid);
            if (tid) {
                return this.http.get(endPoint, {
                    responseType: 'text',
                    headers: new HttpHeaders().set('Authorization', `Bearer ${tid}`)
                });
            } else {
                throw Error('No token');
            }
        })).subscribe((data) => {
            console.log('res', data);
        });
    }

    public importDataFromSheet = (folderId, budgetId) => {

        const param = {folderId: folderId, budgetId: budgetId, customerId: this.auth.appDomain};
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                const sett = this.configService.settings.value;
                const endPoint = sett.value.firebaseApi + '/budget/getDriveDocument';
                // console.log('Got tid', tid);
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST',
                    'Access-Control-Allow-Headers': 'Content-Type'
                });
                // headers.append('Authorization', `Bearer ${tid}`);
                // headers.append('X-App-Domain', this.auth.appDomain);
                if (tid) {
                    console.log(`"folderId":"${folderId}" "budgetId":"${budgetId}"`);
                    return this.http.post(endPoint, param, {
                        // responseType: 'text',
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public runSummaryRecalculateJob = (budgetId) => {
        // const endPoint = 'https://us-central1-extravega-management.cloudfunctions.net/helloWorld/budget';
        if (!budgetId) {
            throw Error('Budget Id is not defined');
        }


        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                const sett = this.configService.settings.value;
                const endPoint = sett.value.firebaseApi + '/budget/runSummaryRecalculateJob/' + this.auth.appDomain + '/' + budgetId;
                // console.log('Got tid', tid);
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                // headers.append('Authorization', `Bearer ${tid}`);
                // headers.append('X-App-Domain', this.auth.appDomain);
                if (tid) {
                    return this.http.get(endPoint, {
                        responseType: 'json',
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public getBudgetPaymentStatus = (budgetId, projectNr, finalOffer, domainId, companyId) => {
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                const sett = this.configService.settings.value;
                const endPoint = sett.value.firebaseApi + '/budget/paymentStatus/' + budgetId + '/' + projectNr +
                    '/' + finalOffer + '/' + domainId + '/' + companyId;
                // console.log('Got tid', tid);
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                // headers.append('Authorization', `Bearer ${tid}`);
                // headers.append('X-App-Domain', this.auth.appDomain);
                if (tid) {
                    return this.http.get(endPoint, {
                        responseType: 'text',
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    // call static list of order by budgetid
    public getOrdersByBudget = (budgetId: string) => {
        if (!budgetId) {
            throw Error('Budget Id is not defined');
        }

        return forkJoin([
            this.auth.getUserIdToken().pipe(first()),
            this.configService.settings.pipe(
                filter((value) => value != null), first())
        ]).pipe(
            first(),
            switchMap(([tid, value]) => {
                const sett = value;
                const endPoint = sett.value.firebaseApi + '/material/orders/' + budgetId;
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                // headers.append('Authorization', `Bearer ${tid}`);
                // headers.append('X-App-Domain', this.auth.appDomain);
                if (tid) {
                    return this.http.get<any[]>(endPoint, {
                        responseType: 'json',
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    // Query Prosperworks CRM <-- should we make a Pw Module?
    public getCompanyById = (companyId: string) => {

        return forkJoin([
            this.auth.getUserIdToken().pipe(first()),
            this.configService.settings.pipe(filter((value) => value != null), first())
        ]).pipe(
            first(),
            switchMap(([tid, sett]) => {
                const endPoint = sett.value.prosperworks.endpoint + '/companies/';
                const headers = {
                    'X-App-Domain': this.auth.appDomain,
                    'Authorization': `Bearer ${tid}`,
                    ...sett.value.prosperworks.headers // This is here only for backward compatibility
                };

                console.log(headers);
                console.log(endPoint);

                return this.http.get<Company>(endPoint + companyId, {
                    headers: new HttpHeaders(headers)
                });
            }), first());
    }

    // Query Prosperworks CRM <-- should we make a Pw Module?
    public getPersonById = (personId: string) => {
        return forkJoin([
            this.auth.getUserIdToken().pipe(first()),
            this.configService.settings.pipe(filter((value) => value != null), first())
        ]).pipe(first(),
            switchMap(([tid, sett]) => {
                const endPoint = sett.value.prosperworks.endpoint + '/people/';
                const headers = {
                    'X-App-Domain': this.auth.appDomain,
                    'Authorization': `Bearer ${tid}`,
                    ...sett.value.prosperworks.headers // This is here only for backward compatibility
                };
                headers['X-App-Domain'] = this.auth.appDomain;
                return this.http.get<Company>(endPoint + personId, {
                    headers: new HttpHeaders(headers)
                });
            }), first());
    }

    // Query Prosperworks CRM <-- should we make a Pw Module?
    public getOpportunityById = (opportunityId: string) => {
        return forkJoin([
            this.auth.getUserIdToken().pipe(first()),
            this.configService.settings.pipe(filter((value) => value != null), first())
        ]).pipe(
            first(),
            switchMap(([tid, sett]) => {
                const endPoint = sett.value.prosperworks.endpoint + '/opportunities/';
                const headers = {
                    'X-App-Domain': this.auth.appDomain,
                    'Authorization': `Bearer ${tid}`,
                    ...sett.value.prosperworks.headers // This is here only for backward compatibility
                };

                return this.http.get<any>(endPoint + opportunityId, {
                    headers: new HttpHeaders(headers)
                });
            }), first());
    }

    // Query Prosperworks CRM <-- should we make a Pw Module?
    public getAllPeople = () => {
        return forkJoin([
            this.auth.getUserIdToken().pipe(first()),
            this.configService.settings.pipe(filter((value) => value != null), first())
        ]).pipe(
            first(),
            switchMap(([tid, sett]) => {
                const endPoint = sett.value.prosperworks.endpoint + '/people/search/';
                const headers = {
                    'X-App-Domain': this.auth.appDomain,
                    'Authorization': `Bearer ${tid}`,
                    ...sett.value.prosperworks.headers // This is here only for backward compatibility
                };

                return this.http.post(endPoint,
                    {
                        page_size: 200,
                        sort_by: 'name'
                    },
                    {
                        headers: new HttpHeaders(headers)
                    });
            }));
    }

    // Query Prosperworks CRM <-- should we make a Pw Module?
    public getAllCompanies = () => {
        return forkJoin([
            this.auth.getUserIdToken().pipe(first()),
            this.configService.settings.pipe(filter((value) => value != null), first())
        ]).pipe(
            first(),
            switchMap(([tid, sett]) => {
                const endPoint = sett.value.prosperworks.endpoint + '/companies/search/';
                const headers = {
                    'X-App-Domain': this.auth.appDomain,
                    'Authorization': `Bearer ${tid}`,
                    ...sett.value.prosperworks.headers // This is here only for backward compatibility
                };

                return this.http.post<Company[]>(endPoint,
                    {
                        page_size: 200,
                        sort_by: 'name'
                    },
                    {
                        headers: new HttpHeaders(headers)
                    });
            }));
    }

    public getFilteredCompanies = (q: string) => {
        return this.http.get<Company[]>('https://api.extravega.wonder-sys.com/api/company?q=' + q);
    }

    public getCompany = (id: string) => {
        return this.http.get<Company[]>('https://api.extravega.wonder-sys.com/api/company/' + id);
    }

    public getPeopleByCompany = (id: string) => {
        return this.http.get<Company[]>('https://api.extravega.wonder-sys.com/api/peopleByCompany/' + id);
    }

    // public addUsers(userId: string) {
    //   if (userId && userId.trim().length) {
    //     this.userCollectionRef.add({
    //       email: userId,
    //       role: 'user'
    //     }).then(function (docRef) {
    //       console.log('Document written with ID: ', docRef.id);
    //     })
    //       .catch(function (error) {
    //         console.error('Error adding document: ', error);
    //       });
    //   }
    // }

    public getAppUsers() {
        return this.userCollectionRef.snapshotChanges();
    }

    public getAppUsersOnce() {
        return this.userCollectionRef.get();
    }

    public getBudgetsListPaginated(pageSize: number, startAt: number, filterQuery: BudgetListFilter) {
        return combineLatest([this.auth.getUserIdToken(), this.configService.settings.pipe(filter(s => s !== null))]).pipe(
            first(),
            switchMap(([tid, settings]) => {
                const endPoint = settings.value.firebaseApi + '/budget/paginatedList';
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                if (tid) {
                    return this.http.post<{ data: any[], totalSize: number }>(endPoint, {
                        pageSize,
                        startAt,
                        filter: filterQuery
                    }, {
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public getQuickBudgetList() {
        const qf = (ref) => ref.where('searchMode', '==', 'quick');
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION, qf);
        return budgetCollRef.valueChanges();
    }

    public getBudgetList = (filterIt?: any) => {
        const appUser = this.auth.currentUser;
        let qf;
        if (filterIt && filterIt.status === 'new') {
            if (appUser.type && appUser.type.includes('cto')) {
                qf = (ref) => ref
                    .where('status', '==', 'new')
                    .where('searchMode', '==', 'quick')
                    .orderBy('creation', 'desc');
            } else {
                console.log('user', appUser.googleUid);
                qf = (ref) => ref
                    .where('assignee.googleUid', '==', appUser.googleUid)
                    .where('searchMode', '==', 'quick')
                    .orderBy('creation', 'desc');
            }
        } else {
            qf = (ref) => ref.where('searchMode', '==', 'quick').orderBy('creation', 'desc');
        }
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION, qf);
        return budgetCollRef.valueChanges();
    }

    public getNotArchivedBudgetList = () => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION,
            (ref) => ref.where('status', '!=', 'archived').orderBy('status', 'desc').orderBy('creation', 'desc'));
        return budgetCollRef.valueChanges();
    }

    public getBudgetsForWorkersList() {

        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION, (ref) => ref.where('workerList', '==', true));
        return budgetCollRef.valueChanges();
    }

    public selectForWorkersList(id: string) {

        return this.cDR.collection<Budget>(BUDGET_COLLECTION).doc(id).update({workerList: true});
    }

    public deselectForWorkersList(id: string) {

        return this.cDR.collection<Budget>(BUDGET_COLLECTION).doc(id).update({workerList: false});
    }

    public getProjectList = (filterIt?: any) => {
        let qf;
        qf = (ref) => ref
            // .where('project.projectNr', '>', '')
            .orderBy('project.projectNr', 'desc').limit(500);

        return this._getBudgetCollectionRef(qf).pipe(
            switchMap((bcollection) => {
                return bcollection.valueChanges();
            }));
    }

    public getBudgetByRef = (ref) => {
        return this.afs.doc<Budget>(ref.path).snapshotChanges();
    }

    public getSimpleBudgetSummaryBalance(budgetId: string) {
        return this.cDR.collection(SUMMARY_COLLECTION).doc(budgetId).ref.get();
    }

    public getBudgetSummaryBalance(budgetId: string): Observable<SummaryBalanceWrapper> {
        return this.cDR.collection<SummaryBalanceWrapper>(SUMMARY_COLLECTION).doc(budgetId).valueChanges();
    }

    public getSimpleBudget(budgetId: string) {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const itemDoc = budgetCollRef.doc<Budget>(budgetId);
        return itemDoc.valueChanges();
    }

    public getBudget = (bdgtId: string) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const itemDoc = budgetCollRef.doc<Budget>(bdgtId);
        return itemDoc.valueChanges().pipe(
            switchMap((b: Budget) => {
                if (!b) {
                    console.error('Budget not found with budgetId', bdgtId);
                    return of(null);
                }
                if (b.status !== 'archived') {
                    this.keepProsperworksInfoUpdated(bdgtId, b);
                }
                return of(b);
            }));
    }

    /**
     * Asyncronously keep budget prosperworks infos updated, from time to time..
     * @param budgetId
     * @param budget
     */
    keepProsperworksInfoUpdated(budgetId: string, budget) {
        if (budget.prosperworks && budget.prosperworks.opportunity_id) {
            console.log('getting data from prosperworks: ', budget.prosperworks.opportunity_id);

            this.getOpportunityById(budget.prosperworks.opportunity_id).pipe(
                take(1)).subscribe((pwData) => {
                console.log('Gathered prosperworks updated infos:', pwData);

                if (budget.prosperworks.opportunity_id === pwData.id) {
                    if (budget.prosperworks.company_name !== pwData.company_name
                        || budget.prosperworks.primary_contact_id !== pwData.primary_contact_id) {
                        const prosperworks_result = {
                            opportunity_id: pwData.id,
                            name: pwData.name,
                            company_id: pwData.company_id,
                            company_name: pwData.company_name,
                            details: pwData.details,
                            primary_contact_id: pwData.primary_contact_id
                        };

                        console.log('Updating prosperworks data in budget as they changed on Copper:', pwData);
                        this.patchBudget(budgetId, {prosperworks: prosperworks_result});
                    }
                }
            });
        } else {
            console.warn('This budget has no prosperworks data associated?');
        }
    }

    public patchBudget = (bdgtId, data: Partial<Budget>) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const itemDoc = budgetCollRef.doc<Budget>(bdgtId);
        const appUser = this.auth.currentUser;
        data.modifiedBy = {
            displayName: appUser.displayName,
            googleUid: appUser.googleUid,
            email: appUser.email
        };
        data.modifiedOn = new Date();
        return itemDoc.update(data);
    }

    public getBudgetCurrency(budgetId: string) {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const itemDoc = budgetCollRef.doc<Budget>(budgetId);
        return itemDoc.valueChanges().pipe(map((item: Budget) => item.export ? 'USD' : 'EUR'));
    }

    public patchSubBudget = (bdgtId, subBudgetId, data: Partial<SubBudget>) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const itemDoc = budgetCollRef.doc<Budget>(bdgtId)
            .collection('sub-budgets').doc<SubBudget>(subBudgetId);
        return itemDoc.update(data);
    }

    public patchOrder = (bdgtId, data: Partial<Order>) => {
        const itemDoc = this.cDR.collection<Order>(ORDER_COLLECTION).doc<Order>(bdgtId);
        return itemDoc.update(data);
    }

    public getSubBudgetResume(budgetId: string) {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const itemDoc = budgetCollRef.doc<Budget>(budgetId);
        return itemDoc.collection('sub-budgets-resume').doc('summary').valueChanges();
    }

    public getSubBudgetCollection = (bdgtId) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const subBudgetCollRef = budgetCollRef.doc<Budget>(bdgtId)
            .collection<SubBudget>('sub-budgets', ref => ref.orderBy('created'));
        return subBudgetCollRef.valueChanges();
    }

    public getAllBudgetActivities = (bdgtId) => {

        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const subBudgetCollRef = budgetCollRef.doc<Budget>(bdgtId)
            .collection<SubBudget>('sub-budgets', ref => ref.orderBy('created'))
            .snapshotChanges().pipe(map((subs: any[]) => {
                console.log('----------------------');
                const activeSubs = subs.filter((s) => (s.payload.doc.data().deleted !== true && s.payload.doc.data().calculate !== false));
                return Promise.all(activeSubs.map(sub => {
                        return sub.payload.doc.ref.collection('activities').get();
                    })
                );
            }));
        return subBudgetCollRef;
    }

    public getSubBudgetCollectionSnapshot = (bdgtId) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const subBudgetCollRef = budgetCollRef.doc<Budget>(bdgtId)
            .collection<SubBudget>('sub-budgets', ref => ref.orderBy('created'));
        return subBudgetCollRef.snapshotChanges();
    }

    public getReportSubBudget = (bdgtId) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const subBudgetCollRef = budgetCollRef.doc<Budget>(bdgtId)
            .collection<SubBudget>('sub-budgets', ref => ref.where('report', '==', true).limit(1));
        return subBudgetCollRef.snapshotChanges();
    }

    public setReportSubBudget(bdgtId): Observable<any> {
        const param = {bdgtId: bdgtId};
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                const sett = this.configService.settings.value;
                const endPoint = sett.value.firebaseApi + '/budget/setReportSubBudget';
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                if (tid) {
                    return this.http.post(endPoint, param, {
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public getSubBudget = (bdgtId, subbdgId) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const itemDoc = budgetCollRef.doc<Budget>(bdgtId)
            .collection('sub-budgets').doc<SubBudget>(subbdgId);
        return itemDoc.valueChanges();
    }

    public updateSubBudget = (bdgtId, subBudgetId, data: SubBudget, batch?: firebase.firestore.WriteBatch) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const itemDoc = budgetCollRef.doc<Budget>(bdgtId)
            .collection('sub-budgets').doc<SubBudget>(subBudgetId);

        if (!data.createdBy) {
            data.createdBy = AppUserUtil.getUserPartial(this.auth.currentUser);
        }

        if (data instanceof SubBudgetModel) {
            data = data.toPlainObj();
        }

        if (batch) {
            batch.set(itemDoc.ref, data, {merge: true});
            return;
        } else {
            return itemDoc.set(data, {merge: true});
        }
    }

    public updateSubBudgetField = (bdgtId, subBudgetId, data: Partial<SubBudget>) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const itemDoc = budgetCollRef.doc<Budget>(bdgtId)
            .collection('sub-budgets').doc<SubBudget>(subBudgetId);

        return itemDoc.update(data);
    }

    /**
     * Flag the sub budget as deleted
     * @param bdgtId The Budget Id in the collection
     * @param subBudgetId The SubBudget Id in the collection
     * @memberof DataService
     */
    public deleteSubBudget = (bdgtId, subBudgetId) => {
        console.log('deleting budget: ', bdgtId, subBudgetId);
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const itemDoc = budgetCollRef.doc<Budget>(bdgtId)
            .collection('sub-budgets').doc<SubBudget>(subBudgetId);
        return itemDoc.update({
            deleted: true
        });
    }

    /**
     * Copy the subbudget
     * @param bdgtId The Budget Id in the collection
     * @param subBudgetId The SubBudget Id in the collection
     * @param generatedBy The type of user who triggered the copy
     * @memberof DataService
     */
    public copySubBudget = async (bdgtId: string, subBudgetId: string, generatedBy: GeneratedBy) => {
        console.log('Copying sub-budget: ', subBudgetId);
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const subBudgetCollRef = budgetCollRef
            .doc(bdgtId).collection<SubBudget>(SUBBUDGET_COLLECTION);

        const subBudgetRef = await subBudgetCollRef
            .doc<SubBudget>(subBudgetId)
            .ref.get();
        const subBudget = <SubBudget>subBudgetRef.data();

        console.log('Got original subBudget: ', subBudgetId, subBudget.description, subBudget);
        const id = this.afs.createId();
        subBudget.created = firebase.firestore.FieldValue.serverTimestamp();
        subBudget.description = 'Copy of ' + subBudget.description;
        subBudget.createdBy = AppUserUtil.getUserPartial(this.auth.currentUser);
        subBudget.id = id;
        subBudget.generatedBy = generatedBy;
        subBudget.report = false; // There should be only one

        await subBudgetCollRef.doc(id).set(subBudget);
        console.log('subBudget set.');

        const activitiesRef = budgetCollRef
            .doc(bdgtId)
            .collection<SubBudget>(SUBBUDGET_COLLECTION)
            .doc<SubBudget>(subBudgetId)
            .collection('activities');
        const activities = await activitiesRef.ref.get();

        for (const activity of activities.docs) {
            console.log('copying activities: ', activity.id, <Activity>activity.data());
            const aData = <Activity>activity.data();
            // Reset finals: don't need to be copied
            delete aData.finalHours;
            await subBudgetCollRef.doc(id).collection('activities').doc(activity.id).set(aData);
        }

        const MaterialsRef = budgetCollRef
            .doc(bdgtId)
            .collection<SubBudget>(SUBBUDGET_COLLECTION)
            .doc<SubBudget>(subBudgetId)
            .collection('materials');
        const materials = await MaterialsRef.ref.get();

        for (const material of materials.docs) {
            console.log('copying material: ', material.id, <Material>material.data());
            const mData = <Material>material.data();
            // Reset finals: don't need to be copied
            delete mData.finalAmount;
            delete mData.finalQuantity;
            delete mData.processedOrders;
            mData.id = this.afs.createId();
            await subBudgetCollRef.doc(id).collection('materials').doc(mData.id).set(mData);
        }

        console.log('SubBudget copied successfully. ', id);
        return id;
    }

    public getSBActivities = (bdgtId, subbdgId) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const subBudgetActivitiesCollRef = budgetCollRef
            .doc(bdgtId).collection('sub-budgets').doc(subbdgId)
            .collection<Activity>('activities');
        return subBudgetActivitiesCollRef.snapshotChanges();
    }

    public getSBActivity = (bdgtId, subbdgId, actId) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const subBudgetActivityRef = budgetCollRef
            .doc(bdgtId).collection('sub-budgets').doc(subbdgId)
            .collection<Activity>('activities').doc<Activity>(actId);
        return subBudgetActivityRef.valueChanges();
    }

    public setSBActivity = (bdgtId, subbdgId, actId, data: Activity) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const subBudgetActivityRef = budgetCollRef
            .doc(bdgtId).collection('sub-budgets').doc(subbdgId)
            .collection<Activity>('activities').doc<Activity>(actId);
        console.log('Write on', subBudgetActivityRef.ref.path);
        return subBudgetActivityRef.set(data);
    }

    public getSBMaterials = (bdgtId, subbdgId) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const subBudgetMaterialCollRef = budgetCollRef
            .doc(bdgtId).collection('sub-budgets').doc(subbdgId)
            .collection<Material>('materials');
        return subBudgetMaterialCollRef.snapshotChanges();
    }

    public getStorage = () => {
        const storageCollRef = this.cDR.collection<Storage>(STORAGE_COLLECTION);
        return storageCollRef.snapshotChanges();
    }

    public getSBMaterial = (bdgtId, subbdgId, matId) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const subBudgetMaterialRef = budgetCollRef
            .doc(bdgtId).collection('sub-budgets').doc(subbdgId)
            .collection<Material>('materials').doc(matId);
        return subBudgetMaterialRef.valueChanges();
    }

    public setSBMaterial = (bdgtId: string, subbdgId: string, matId: string, data: Material, batch?: firebase.firestore.WriteBatch) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const subBudgetMaterialRef = budgetCollRef
            .doc(bdgtId).collection('sub-budgets').doc(subbdgId)
            .collection<Material>('materials').doc(matId);

        if (batch) {
            batch.set(subBudgetMaterialRef.ref, data);
            return;
        } else {
            return subBudgetMaterialRef.set(data);
        }
    }

    public addMaterial = (budgetId, subBudgetId, material) => {
        delete material.id;
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const targetSubBudget = budgetCollRef
            .doc(budgetId)
            .collection('sub-budgets')
            .doc(subBudgetId).collection('materials');
        return from(targetSubBudget.add(material));
    }

    public addStorage = (storage: Storage) => {
        const storageCollRef = this.cDR.collection<Storage>(STORAGE_COLLECTION);
        return from(storageCollRef.add(storage));
    }

    public updateMaterial = (budgetId, subBudgetId, material: Material) => {
        if (!material.id) {
            return of(false);
        }
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const targetMaterial = budgetCollRef
            .doc(budgetId)
            .collection('sub-budgets')
            .doc(subBudgetId).collection('materials').doc(material.id);
        return from(targetMaterial.update(material));
    }

    public updateStorage = (id, storage: Storage) => {
        console.log('storageId:', id);
        if (!id) {
            return of(false);
        }
        const storageCollRef = this.cDR.collection<Storage>(STORAGE_COLLECTION);
        const targetStorage = storageCollRef
            .doc(id);
        return from(targetStorage.update(storage));
    }

    public deleteMaterial = (budgetId, subBudgetId, materialId: string) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const targetSubBudget = budgetCollRef
            .doc(budgetId)
            .collection('sub-budgets')
            .doc(subBudgetId).collection('materials');
        const material = targetSubBudget.doc(materialId);
        return from(material.delete());
    }

    public deleteStorage = (storageId: string) => {
        const storageCollRef = this.cDR.collection<Storage>(STORAGE_COLLECTION);
        const targetStorage = storageCollRef.doc(storageId);
        return from(targetStorage.delete());
    }

    public deleteBudget = (budgetId) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        return from(budgetCollRef.doc(budgetId).delete());
    }

    public commitActivities = (budgetId, subBudgetId, activitiesValue) => {
        // console.log('pav', activitiesValue);
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const targetSubBudget = budgetCollRef
            .doc(budgetId)
            .collection('sub-budgets')
            .doc(subBudgetId).collection('activities');
        const pArray = [];
        for (const [a, h] of Object.entries(activitiesValue)) {
            pArray.push(
                targetSubBudget.doc(a).update({'hours': h})
            );
        }
        return from(Promise.all(pArray));
    }

    public createExportBudget = (budget: Budget) => {
        const budgetCollRef = this.cDR.collection(BUDGET_COLLECTION);
        const id = this.afs.createId();
        console.log('Creating export, new ID: ', id);
        const exBudgetRef = budgetCollRef.doc(id);

        return this.getExchangeRateInternal('USD').pipe(first()).subscribe(response => {
            console.log(response);
            if(!response['rates']['USD']) throw Error('Can not create export as exchange rate not found. Please try again later');

            const bdgtVal: Partial<Budget> = {
                id,
                budgetNr: budget.budgetNr + '-export',
                creation: new Date(),
                margin: {
                    generalExpenses: 12,
                    ciTaxesPercentage: 8.875,
                    profit: 8.875,
                    capitalImprovement: true
                },
                description: budget.description,
                prosperworks: budget.prosperworks,
                tags: budget.tags,
                status: 'assigned',
                driveFolderId: budget.driveFolderId,
                export: {
                    from: {
                        budgetNr: budget.budgetNr,
                        id: budget.id
                    },
                    exchangeRate: response['rates']['USD']
                }
            };

            bdgtVal['searchMode'] = 'quick';

            // Clean undefined
            for (const [key, value] of Object.entries(bdgtVal)) {
                if (value === undefined) {
                    delete bdgtVal[key];
                }
            }

            return exBudgetRef.set(bdgtVal)
                .then(() => {


                    this.addSubBudget(exBudgetRef.ref.id, 'Budget0', GeneratedBy.Export);

                    return this.patchBudget(budget.id, {
                        exportBudget: exBudgetRef.ref
                    }).then(() => {
                        return this.runSummaryRecalculateJob(exBudgetRef.ref.id);
                    });
                });
        });


    }

    public addSubBudget = (bdgtId, description: string, generatedBy: GeneratedBy) => {
        const budgetCollRef = this.cDR.collection<Budget>(BUDGET_COLLECTION);
        const subBudgetCollRef = budgetCollRef
            .doc(bdgtId).collection<SubBudget>('sub-budgets');

        // Check if budget is closed
        return budgetCollRef.doc<Budget>(bdgtId).valueChanges().pipe(
            tap(() => console.log('BUDGET CHANGE')),
            first(),
            switchMap((budget: Budget) => {
                const id = this.afs.createId();
                const subBudget: SubBudget = {
                    id,
                    description,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    deleted: null,
                    active: true,
                    generatedBy: generatedBy,
                    createdBy: AppUserUtil.getUserPartial(this.auth.currentUser),
                    calculate: true // This determine if the subbudget is included in the total
                };
                if (budget.project) {
                    subBudget.overrideEdit = true;
                }
                return subBudgetCollRef.doc(id).set(subBudget)
                    .then(() => {
                        // Create the activities from template (this could be moved to BE)
                        this.activitiesTemplate$.value.activities.forEach((a, i) => {
                            const actName = 'activity' + String(i).padStart(2, '0');
                            subBudgetCollRef.doc(id).collection('activities').doc(actName).set(a);
                        });
                        return true;
                    })
                    .catch((err) => {
                        console.error('Failed while create subbudget', err.message);
                        return false;
                    });
            }));
    }

    public assignBudget = (user: AclUser, bdgtId: string, deliveryDate: Date, noteToPrjMgr: string) => {

        // budget/:budgetId/:userId/:domain/
        const endPoint = this.settings.value.firebaseApi + '/budget/assignBudget';
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                const userId = user.id;
                if (tid) {
                    const urlRequest = endPoint + '/' + bdgtId;
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain
                    });
                    return this.http.patch(urlRequest, {
                        userId,
                        domain: this.auth.appDomain,
                        deliveryThr: deliveryDate.getTime(),
                        noteToPrjMgr: noteToPrjMgr
                    }, {
                        responseType: 'text',
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public reassignBudget = (user: AclUser, bdgtId: string) => {

        // budget/:budgetId/:userId/:domain/
        const endPoint = this.settings.value.firebaseApi + '/budget/reassignBudget';
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                // console.log('Got tid', tid);
                const userId = user.id;
                if (tid) {
                    const urlRequest = endPoint + '/' + bdgtId;
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain
                    });
                    return this.http.patch(urlRequest, {
                        userId,
                        domain: this.auth.appDomain
                    }, {
                        responseType: 'text',
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public deleteBudgetApi = (userId: string, budgetId: string) => {

        // budget/:budgetId/:userId/:domain/
        const endPoint = this.settings.value.firebaseApi + '/budget/delete';
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                if (tid) {
                    const urlRequest = endPoint + '/' + budgetId;
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain
                    });
                    return this.http.post(urlRequest, {
                        userId,
                        budgetId
                    }, {
                        responseType: 'text',
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public openProject = (budgetId: string, projectNr: string, finalOffer: number, projectManager: AclUser,
                          paymentMethod: string, paymentMethodCode: string, deliveryDate: Date) => {
        // createProject/:budgetId/:userId/:projectNr/:orderTotal
        const endPoint = this.settings.value.firebaseApi + '/budget/createProject';
        const user = this.auth.currentUser;
        // console.log('User is User', user);
        return this.auth.getUserIdToken().pipe(first(), switchMap((tid) => {
            // console.log('Got tid', tid);
            const userId = user.googleUid;
            if (tid) {
                const urlRequest = endPoint + '/' + budgetId;
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                return this.http.patch(urlRequest,
                    {
                        userId,
                        projectNr,
                        finalOffer,
                        projectManager: projectManager.id,
                        paymentMethod,
                        paymentMethodCode,
                        deliveryDate
                    }
                    , {
                        responseType: 'text',
                        headers: headers
                    });
            } else {
                throw Error('No token');
            }
        }));
    }

    public createSummaryBalance = (budgetId: string) => {
        const endPoint = this.settings.value.firebaseApi + '/budget/createSummaryBalance';
        const user = this.auth.currentUser;
        // console.log('User is User', user);
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                console.log('Got tid', tid);
                const userEmail = user.email;
                if (tid) {
                    const urlRequest = endPoint;
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain
                    });
                    return this.http.post(urlRequest,
                        {
                            userEmail: userEmail,
                            budgetId: budgetId,
                            domainId: this.auth.appDomain
                        }
                        , {
                            responseType: 'text',
                            headers: headers
                        });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public createSummaryPdf = (budgetId) => {
        console.log('CREATING PDF##############');
        const endPoint = this.settings.value.firebaseApi + '/budget/createSummaryPdf';
        const user = this.auth.currentUser;
        // console.log('User is User', user);
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                console.log('Got tid', tid);
                const userEmail = user.email;
                if (tid) {
                    const urlRequest = endPoint;
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain
                    });
                    return this.http.post(urlRequest,
                        {
                            userEmail: userEmail,
                            budgetId: budgetId,
                            domainId: this.auth.appDomain
                        }
                        , {
                            responseType: 'text',
                            headers: headers
                        });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public sendNdaReminder = (recipients, vendor) => {
        const endPoint = this.settings.value.firebaseApi + '/order/sendNdaReminder';

        // console.log('User is User', user);
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                console.log('Got tid', tid);
                if (tid) {
                    const urlRequest = endPoint;
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain,
                        'Access-Control-Allow-Origin': '*'
                    });
                    return this.http.post(urlRequest,
                        {
                            recipients,
                            vendor,
                            userFrom: this.auth.currentUser.email
                        }
                        , {
                            headers: headers
                        });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public createInternalProject = (projectNr: string, orderTotal: number, description: string, type: string, year: string = '2019') => {
        // createProject/:budgetId/:userId/:projectNr/:orderTotal

        if (Number.isNaN(parseInt(projectNr, 10))) {
            throw Error('PrjectNr must be parsable as integer');
        }

        const endPoint = this.settings.value.firebaseApi + '/budget/createInternalProject';
        const user = this.auth.currentUser;
        console.log('User is User', user);
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                // console.log('Got tid', tid);
                // const user = user.uid;
                if (tid) {
                    const urlRequest = endPoint;
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain
                    });
                    return this.http.post(urlRequest,
                        {
                            user: (({displayName, googleUid, email}) => ({displayName, googleUid, email}))(user),
                            projectNr,
                            orderTotal,
                            description,
                            type,
                            year
                        }
                        , {
                            responseType: 'text',
                            headers: headers
                        });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public getInternalProjects = () => {
        const q = (ref) => ref.where('project.internal', '==', true).orderBy('project.projectNr', 'desc');
        const budgetCollection = this.cDR.collection<Budget>(BUDGET_COLLECTION, q);
        return budgetCollection.valueChanges();
    }

    public changeFinalOffer = (budgetInfo, updateObj, auditInfo, currentUser) => {
        const data = {budgetInfo, auditInfo, updateObj, currentUser};
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                const sett = this.configService.settings.value;
                const endPoint = sett.value.firebaseApi + '/budget/changeFinalOffer';
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                if (tid) {
                    return this.http.post(endPoint, data, {
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public getHoursReport(fromD: string, to: string): any {
        console.log('getHoursReport ', fromD, to);
        const endPoint = this.settings.value.firebaseApi + '/budget/getHoursReport';
        return this.auth.getUserIdToken().pipe(first(),
            switchMap((tid) => {
                console.log('Got tid', tid);
                if (tid) {
                    const urlRequest = endPoint;
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain,
                        'Access-Control-Allow-Origin': '*'
                    });

                    return this.http.post(urlRequest, {
                        from: fromD,
                        to: to
                    }, {
                        responseType: 'json',
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }),
            first());
    }

    public archiveBudget = (budgetId, forceRevenue: boolean, finalInvoiced?) => {
        console.log('CREATING PDF##############');
        const endPoint = this.settings.value.firebaseApi + '/budget/archiveBudget';
        const user = this.auth.currentUser;
        // console.log('User is User', user);
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                console.log('Got tid', tid);
                const userEmail = user.email;
                if (tid) {
                    const urlRequest = endPoint;
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain
                    });
                    return this.http.post(urlRequest,
                        {
                            userEmail: userEmail,
                            budgetId: budgetId,
                            domainId: this.auth.appDomain,
                            finalInvoiced: finalInvoiced,
                            forceRevenue: forceRevenue
                        }
                        , {
                            responseType: 'text',
                            headers: headers
                        });
                } else {
                    throw Error('No token');
                }
            }));
    }

    /************************/
    public getTimereportList = (fromDate: Date, toDate: Date, user?: AclUser) => {
        let q = (ref) => ref.orderBy('date', 'desc').where('date', '>=', fromDate).where('date', '<=', toDate);
        if (user) {
            q = (ref) => ref.where('user.email', '==', user.email).where('date', '>=', fromDate).where('date', '<=', toDate).orderBy('date', 'desc');
        }
        const timereportCollRef = this.cDR.collection<Timereport>(TIMERREPORT_COLLECTION, q);
        return timereportCollRef.valueChanges({idField: 'id'});
    }

    /************************/
    /** Time Report methods */

    public getTimereportsListPaginated(pageSize: number, startAt: number, filterQuery: TimereportListFilter) {
        return combineLatest([this.auth.getUserIdToken(), this.configService.settings.pipe(filter(s => s !== null))]).pipe(
            first(),
            switchMap(([tid, settings]) => {
                const endPoint = settings.value.firebaseApi + '/timereport/paginatedList';
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                if (tid) {
                    return this.http.post<{ data: any[], totalSize: number }>(endPoint, {
                        pageSize,
                        startAt,
                        filter: filterQuery
                    }, {
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public getQuickTimereportsList(filterIt: TimereportListFilter) {
        const query = (ref) => {
            if (filterIt.userEmail) {
                return ref.where('user.email', '==', filterIt.userEmail)
                    .where('date', '>=', filterIt.fromDate).where('date', '<=', filterIt.toDate).where('searchMode', '==', 'quick');
            }

            return ref.where('date', '>=', filterIt.fromDate).where('date', '<=', filterIt.toDate).where('searchMode', '==', 'quick');
        };

        const timereportCollRef = this.cDR.collection<Timereport>(TIMERREPORT_COLLECTION, query);
        return timereportCollRef.valueChanges({idField: 'id'});
    }

    public getTimereportByBudget = (budgetId: string) => {
        const q = (ref) => ref.where('budget.id', '==', budgetId).orderBy('date', 'desc');
        const timereportCollRef = this.cDR.collection<Timereport>(TIMERREPORT_COLLECTION, q);
        return timereportCollRef.snapshotChanges();
    }

    public addTimereport = (timereport: Timereport) => {
        const timereportCollRef = this.cDR.collection<Timereport>(TIMERREPORT_COLLECTION);
        return timereportCollRef.add(timereport);
    }

    public updateTimereport = (id: string, timereport: Timereport) => {
        const timereportCollRef = this.cDR.collection<Timereport>(TIMERREPORT_COLLECTION);
        return timereportCollRef.doc(id).update(timereport);
    }

    public getTimeReport = (id: string) => {
        const timereportCollRef = this.cDR.collection<Timereport>(TIMERREPORT_COLLECTION);
        return timereportCollRef.doc<Timereport>(id).valueChanges();
    }

    public deleteTimereport = (timereport: Timereport) => {
        const timereportCollRef = this.cDR.collection<Timereport>(TIMERREPORT_COLLECTION);
        return timereportCollRef.doc(timereport.id).delete();
    }

    public getReportEmailByUser = (user: AppUser) => {
        const emailReportCollection = this.cDR.collection(EMAILREPORT_COLLECTION,
            (ref) => ref
                .where('user.email', '==', user.email)
                .where('success', '==', true)
        );
        return emailReportCollection.valueChanges();
    }

    public getNextProjectNr = () => {
        // const projSeq = this.cDR.collection(SEQ_COLLECTION).doc<Sequence>('Project');
        // return projSeq.valueChanges().map((seq) => {
        //   return seq.value + seq.increment;
        // });
        const q = (ref) => ref.orderBy('project.projectNr', 'desc').limit(80);
        const budgetCollection = this.cDR.collection<Budget>(BUDGET_COLLECTION, q);
        return budgetCollection.valueChanges().pipe(
            map((budgets: Budget[]) => {
                if (!budgets || !budgets.length) {
                    return '0000';
                }

                // WORKAROUND: skip projectNrs with no-digit chars inside
                const noGoWords = ['5', '6', '7', '8', '9', '10', '4-2020'];
                budgets = budgets.filter(b => {
                    const projectNr = b.project.projectNr;
                    return noGoWords.indexOf(projectNr) === -1 && /^\d+$/.test(projectNr);
                });

                if (!budgets || !budgets.length) {
                    return '0000';
                }
                // console.log('project', budgets);
                const lastPn = parseInt(budgets[0].project.projectNr, 10);
                return (lastPn + 1).toString().padStart(4, '0');
            }));
    }

    public sendDailyReport(data) {
        console.log('Sending daily report with data', data);
        const endPoint = this.settings.value.firebaseApi + '/timereport/daily';
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                console.log('Got tid', tid);
                const userId = data.user.googleUid;
                if (tid) {
                    const urlRequest = endPoint;
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain,
                        'Access-Control-Allow-Origin': '*'
                    });
                    // TODO fix this!!!
                    // const myDate = new Date((<Date>data.reportDate).getTime() + 43200000);
                    let myDate = data.reportDate;

                    // We represent the italian day with the italian midnight that day
                    const md = moment(myDate);
                    const dateItaly = moment.tz({
                        year: md.year(),
                        month: md.month(),
                        day: md.date()
                    }, 'Europe/Rome');
                    myDate = dateItaly.toDate();

                    console.log('My Date', myDate);
                    return this.http.post(urlRequest, {
                        userId,
                        date: myDate
                    }, {
                        responseType: 'json',
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }),
            first());
    }

    public getTimereportsReport(fromD: string, to: string): any {
        console.log('getTimereportsReport ', fromD, to);
        const endPoint = this.settings.value.firebaseApi + '/timereport/hoursReport';
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                console.log('Got tid', tid);
                if (tid) {
                    const urlRequest = endPoint;
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain,
                        'Access-Control-Allow-Origin': '*'
                    });

                    return this.http.post(urlRequest, {
                        from: fromD,
                        to: to
                    }, {
                        responseType: 'json',
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }),
            first());
    }

    /** Orders methods     */
    /********************* */

    public getBrokenOrders = () => {
        this.getOrderList().pipe(first(), map((or: any) => or.map(o => o.payload.doc.data()))).subscribe(orders => {
            // TROVA TUTTI GLI ORDINI CHE HANNO ALMENO UN ITEM CON IL CAMPO invoices === null (papabili per essere broken)
            console.log('-----------------BROKEN ITEMS----------------------');
            this.brokenOrders = orders.filter(o => o.items && o.items.some(it => {
                if (it.invoices === null) {
                    console.log(it.id);
                    return true;
                }

            }));
            console.log('-----------------BROKEN ORDERS----------------------');
            console.log(this.brokenOrders);
            // PER OGNI ORDINE INDIVIDUATO CERCA LE FATTURE CORROTTE
            this.getBrokenInvoices();

        });


    }

    /***********************/

    public getBrokenInvoices() {
        // invoice.order.orderNr
        const hiddenTable = document.createElement('div');
        document.getElementById('hiddenTableContainer').appendChild(hiddenTable);

        this.brokenOrders.forEach(bo => {
            // PER OGNI ORDINE CHE POTREBBE ESSERE ROTTO, TROVA LE FATTURE ASSOCIATE A QUELL'ORDINE
            this.cDR.collection<any>(INVOICES_COLLECTION,
                ref => ref.where('order.orderNr', '==', bo.orderNr)).valueChanges().pipe(first()).subscribe(bi => {

                const result = bi.filter(inv => {
                    return moment(inv.invoiceDate).isSame(new Date('June 1, 2019 00:00:00'), 'month') &&
                        bo.items &&
                        !bo.items.every(it => it.invoices && it.invoices.every(i => i.id !== inv.id));
                });

                this.allBrokenInvoices = this.allBrokenInvoices.concat(result);


                console.log('-----------------' + this.allBrokenInvoices.length + ' BROKEN INVOICES----------------------');
                console.log(this.allBrokenInvoices);

                const hiddenTableRows = this.allBrokenInvoices.map(inv => {

                    const itemToAdd = inv.items.map(it => {
                        return it.description + ' - ' + it.costEach;
                    });

                    const vendorName = inv.order.vendor ? inv.order.vendor.name : '';

                    return `
      <tr>
        <td>${inv.id}</td>
        <td>${inv.invoiceDate}</td>
        <td>${inv.invoiceNr}</td>
        <td>${inv.order.orderNr}</td>
        <td>${vendorName}</td>
        <td>${itemToAdd.join('<br/>')}</td>
      </tr>`;
                });

                hiddenTable.innerHTML = `
      <table style="display:none">
        <thead>
          <tr>
            <th>Invoice Id</th>
            <th>Invoice date</th>
            <th>Invoice Nr</th>
            <th>Order Nr</th>
            <th>Vendor name</th>
            <th>item to add?</th>
          </tr>
        </thead>
        <tbody>
          ${hiddenTableRows.join('')}
        </tbody>
      </table>
    `;
            });
        });

    }

    public getNextOrderNr = () => {
        const orderSeq = this.cDR.collection(SEQ_COLLECTION).doc<Sequence>('Order');
        return orderSeq.valueChanges().pipe(map((seq: Sequence) => {
            return seq.value + 1;
        }));
    }

    public getOrderList = () => {
        console.log('get order list call');
        const orderCollRef = this.cDR.collection<Order>(ORDER_COLLECTION, ref => ref.where('deleted', '==', false)
            .orderBy('date', 'desc'));
        return orderCollRef.valueChanges({idField: 'id'});
    }

    public getQuickOrderList() {
        const orderCollRef = this.cDR.collection<Order>(ORDER_COLLECTION, ref =>
            ref.where('deleted', '==', false).where('searchMode', '==', 'quick'));

        return orderCollRef.get().pipe(map(d => {
            return d.docs
                .map(o => ({...o.data(), id: o.id}))
                .map((order => this.calculateOrderTotalInvoiceAmount(order)));
        }));
    }

    public getOrderListPaginated(pageSize: number, startAt: number, filterQuery: OrderLIstFilter) {
        return combineLatest([this.auth.getUserIdToken(), this.configService.settings.pipe(filter(s => s !== null))]).pipe(
            first(),
            switchMap(([tid, settings]) => {
                const endPoint = settings.value.firebaseApi + '/order/paginatedList';
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                if (tid) {
                    return this.http.post<{ data: any[], totalSize: number }>(endPoint, {
                        pageSize,
                        startAt,
                        filter: filterQuery
                    }, {
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public getOrderListOnce = () => {
        const orderCollRef = this.cDR.collection<Order>(ORDER_COLLECTION, ref => ref
            .where('deleted', '==', false)
            .orderBy('date', 'desc'));
        return orderCollRef.get();
    }

    public markOrderAsDelivered = (orderId, delivered, realDeliveryDate) => {
        return combineLatest([this.auth.getUserIdToken(), this.configService.settings.pipe(filter(s => s !== null))]).pipe(
            first(),
            switchMap(([tid, settings]) => {
                const endPoint = settings.value.firebaseApi + '/order/markAsDelivered';
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                if (tid) {
                    return this.http.post<{ message: string }>(endPoint, {
                        orderId: orderId,
                        delivered: delivered,
                        realDeliveryDate: realDeliveryDate
                    }, {
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public getLimitedOrderListOnce = () => {
        const orderCollRef = this.cDR.collection<Order>(ORDER_COLLECTION, ref => ref
            .where('deleted', '==', false)
            .where('searchMode', '==', 'quick')
            .orderBy('date', 'desc'));
        return orderCollRef.get();
    }

    public getOrder = (orderId) => {
        const orderRef = this.cDR.collection<Order>(ORDER_COLLECTION).doc<Order>(orderId);
        return orderRef.valueChanges();
    }

    public getSingleOrder = (orderId) => {
        const orderRef = this.cDR.collection<Order>(ORDER_COLLECTION).doc<Order>(orderId);
        return orderRef.get();
    }

    public addOrder = (order: Order) => {
        return this.afs.firestore.runTransaction(transaction => {

            const CUSTOMER_DOMAIN = calculateCustomerFromHostname(document.location.hostname);
            const referenceUsingUnderlyingLibrary = this.afs.firestore
                .collection('Customers').doc(CUSTOMER_DOMAIN).collection(SEQ_COLLECTION).doc('Order');
            console.log(typeof (referenceUsingUnderlyingLibrary), referenceUsingUnderlyingLibrary);

            return transaction.get(referenceUsingUnderlyingLibrary).then((orderSeq: any) => {
                const newOrderNum = orderSeq.data().value + 1;
                transaction.update(referenceUsingUnderlyingLibrary, {value: newOrderNum});
                const newOrderNr = this.buildOrderNr(newOrderNum, order.author);
                order.orderNr = newOrderNr;
                order.deleted = false;
                const orderCollRef = this.cDR.collection<Order>(ORDER_COLLECTION);
                console.log('order before add:', order);
                return orderCollRef.add(order);
            });
        });
    }

    public getBatch = () => {
        return this.afs.firestore.batch();
    }

    public deleteOrder = (order: Order) => {
        return this.cDR.collection<Order>(ORDER_COLLECTION).doc(order.id)
            .update({
                deleted: true,
                deletedOn: new Date(),
                deletedBy: this.auth.currentUser.googleUid
            });
    }

    public updateOrder = (orderId, order: Partial<Order>) => {
        const orderRef = this.cDR.collection<Order>(ORDER_COLLECTION).doc(orderId);
        console.log('order before update:', order);
        return orderRef.update(order);
    }

    public updateBudgetStatus = (budgetId, newStatus) => {
        const budgetRef = this.cDR.collection<Budget>(BUDGET_COLLECTION).doc(budgetId);
        return budgetRef.update({status: newStatus});
    }

    /** LASER CUT */
    public getLaserCutNesting = () => {
        const nestingCollRef = this.cDR.collection<LantekNesting>('LaserCut/root/Nesting');
        return nestingCollRef.snapshotChanges();
    }

    public getInvoicesList = () => {
        console.log('get invoice list call');
        const invoicesCollRef = this.cDR.collection<Invoice>(INVOICES_COLLECTION, ref => ref.orderBy('invoiceDate', 'desc'));
        return invoicesCollRef.valueChanges({idField: 'id'});
    }

    public getQuickInvoicesList() {
        const invoicesCollRef = this.cDR.collection<Invoice>(INVOICES_COLLECTION, ref => ref.where('searchMode', '==', 'quick'));
        return invoicesCollRef.valueChanges({idField: 'id'});
    }

    public getInvoicesListPaginated(pageSize: number, startAt: number, filterQuery: InvoicesLIstFilter) {

        return combineLatest([this.auth.getUserIdToken(), this.configService.settings.pipe(filter(s => s !== null))]).pipe(
            first(),
            switchMap(([tid, settings]) => {
                const endPoint = settings.value.firebaseApi + '/invoice/paginatedList';
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                if (tid) {
                    return this.http.post<{ data: any[], totalSize: number }>(endPoint, {
                        pageSize,
                        startAt,
                        filter: filterQuery
                    }, {
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    /***********************/

    public getInvoicesListOnce = () => {
        const invoicesCollRef = this.cDR.collection<Invoice>(INVOICES_COLLECTION, ref => ref.orderBy('invoiceDate', 'desc'));
        return invoicesCollRef.get();
    }

    public getInvoicesCosmoList = () => {
        const invoicesCollRef = this.cDR.collection<Invoice>(INVOICES_COLLECTION, ref => ref.where('sentToAccounting', '==', true)
            .orderBy('invoiceDate', 'desc'));
        return invoicesCollRef.valueChanges({idField: 'id'});
    }

    public getInvoice = (invoicesId: string) => {
        const invoicesRef = this.cDR.collection<Invoice>(INVOICES_COLLECTION).doc<Invoice>(invoicesId);
        return invoicesRef.valueChanges();
    }

    public getInvoicesOnce = (invoicesId) => {
        const invoicesRef = this.cDR.collection<Invoice>(INVOICES_COLLECTION).doc<Invoice>(invoicesId);
        return invoicesRef.get();
    }

    public getInvoiceLog = (invoicesId) => {
        const invoiceLogRef = this.cDR.collection(INVOICES_COLLECTION).doc<Invoice>(invoicesId).collection('log');
        return invoiceLogRef.valueChanges();
    }

    public getInvoicesCosmo = (invoicesId) => {
        const invoicesRef = this.cDR
            .collection<Invoice>(INVOICES_COLLECTION, ref =>
                ref.where('sentToAccounting', '==', true)).doc<Invoice>(invoicesId);
        return invoicesRef.valueChanges();
    }

    public addInvoices = (invoices: Invoice) => {
        invoices.sentToAccounting = false;
        invoices.registeredToCosmo = 'ready';
        const invoicesCollRef = this.cDR.collection<Invoice>(INVOICES_COLLECTION);
        return invoicesCollRef.add(invoices);
    }

    public updateInvoices = (invoicesId, edits: Partial<Invoice>) => {
        console.log('updating', invoicesId, edits);
        return this.cDR.collection<Invoice>(INVOICES_COLLECTION).doc(invoicesId).update(edits);
    }

    public getDdtList = () => {
        const ddtCollRef = this.cDR.collection<Ddt>(DDT_COLLECTION, ref => ref.orderBy('ddtDate', 'desc'));
        return ddtCollRef.snapshotChanges();
    }

    public getDdt = (ddtId) => {
        const ddtRef = this.cDR.collection<Ddt>(DDT_COLLECTION).doc<Ddt>(ddtId);
        return ddtRef.valueChanges();
    }

    public addDdt = (ddt: Ddt) => {
        const ddtCollRef = this.cDR.collection<Ddt>(DDT_COLLECTION);
        return ddtCollRef.add(ddt);
    }

    /*public deleteInvoices = (invoices: Invoices) => {
      return this.cDR.collection<Invoices>(INVOICES_COLLECTION).doc(invoices.id)
        .update({
          deleted: true,
          deletedOn: new Date(),
          deletedBy: this.auth.currentUser.googleUid,
        });
    }*/

    public updateDdt = (ddtId, ddt: Partial<Ddt>) => {
        const ddtRef = this.cDR.collection<Ddt>(DDT_COLLECTION).doc(ddtId);
        return ddtRef.update(ddt);
    }

    /***********************/

    public getQuickDdtList() {
        const orderCollRef = this.cDR.collection<Ddt>(DDT_COLLECTION, ref =>
            ref.where('searchMode', '==', 'quick'));

        return orderCollRef.valueChanges({idField: 'id'});
    }

    public getDdtListPaginated(pageSize: number, startAt: number, filterQuery: DdtListFilter) {

        return combineLatest([this.auth.getUserIdToken(), this.configService.settings.pipe(filter(s => s !== null))]).pipe(
            first(),
            switchMap(([tid, settings]) => {
                const endPoint = settings.value.firebaseApi + '/ddt/paginatedList';
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                if (tid) {
                    return this.http.post<{ data: any[], totalSize: number }>(endPoint, {
                        pageSize,
                        startAt,
                        filter: filterQuery
                    }, {
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    /** Users methods     */
    /********************* */

    public getUserList = () => {
        const userCollRef = this.cDR.collection<User>(USER_COLLECTION, ref => ref.where('deleted', '==', false)
            .orderBy('displayName'));
        return userCollRef.snapshotChanges();
    }

    public getActiveUserList = () => {
        const userCollRef = this.cDR.collection<User>(USER_COLLECTION, ref => ref.where('deleted', '==', false).where('active', '==', true)
            .orderBy('displayName'));
        return userCollRef.snapshotChanges();
    }

    public getUser = (userId) => {
        const userRef = this.cDR.collection<User>(USER_COLLECTION).doc<User>(userId);
        return userRef.valueChanges();
    }

    public addUser = (user: User) => {
        const userCollRef = this.cDR.collection<User>(USER_COLLECTION);
        return userCollRef.add(user);
    }

    public deleteUser = (user: User) => {
        return this.cDR.collection<User>(USER_COLLECTION).doc(user.id)
            .update({
                deleted: true
            });
    }


    /***********************/

    public updateUser = (userId, user: Partial<User>) => {
        const userRef = this.cDR.collection<User>(USER_COLLECTION).doc(userId);
        return userRef.update(user);
    }

    public getUserGoogleUid = (userId) => {
        const endPoint = this.settings.value.firebaseApi + '/users/createInternalProject';
        const user = this.auth.currentUser;
        console.log('User is User', user);
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                // console.log('Got tid', tid);
                // const user = user.uid;
                if (tid) {
                    const urlRequest = endPoint;
                    const headers = new HttpHeaders({
                        'Authorization': `Bearer ${tid}`,
                        'X-App-Domain': this.auth.appDomain
                    });
                    return this.http.post(urlRequest,
                        {
                            userId
                        }
                        , {
                            responseType: 'text',
                            headers: headers
                        });
                } else {
                    throw Error('No token');
                }
            }));
    }

    /** Roles methods     */
    /********************* */

    public getRoleList = () => {
        const roleCollRef = this.cDR.collection<Role>(ROLE_COLLECTION, ref => ref.orderBy('permissions', 'asc'));
        return roleCollRef.snapshotChanges();
    }

    public getRole = (roleId) => {
        // const roleRef = this.cDR.collection<Roles>(ROLE_COLLECTION).doc<Roles>(roleId);
        // return roleRef.valueChanges();
    }

    /** UTILITY FUNCTIONS      */
    /************************* */

    public getPaymentMethods = () => {
        const paymentMethodsCollRef = this.cDR.collection<PaymentMethods>(PAYMENTMETHODS_COLLECTION, ref => ref.orderBy('methodValue'));
        return paymentMethodsCollRef.snapshotChanges();
    }

    public getDriveFileById(fileId) {
        return this.googleApiService.onLoad().pipe(take(1), map(() => {
            return gapi.client.load('drive', 'v3').then(() => {
                return gapi.client.drive.files.get({
                    supportsTeamDrives: true,
                    fileId: fileId,
                    fields: '*'
                }).then(resp => {
                    console.log('resp', resp);
                    return resp;
                });
            });
        }));
    }

    public getVendorNda(vendor) {
        try {
            const cfNdaId = this.settings.value.prosperworks.cfNdaId;

            if (!cfNdaId) {
                throw Error(`nda field id is not defined in settings`);
            }
            console.log('getVendorNda custom fields:', cfNdaId, vendor.custom_fields);
            const vendorNdaFile = vendor.custom_fields.find(cf => cf.custom_field_definition_id.toString() === cfNdaId);

            if (!vendorNdaFile || !vendorNdaFile.value) {
                throw Error(`Vendor nda file not defined`);
            }

            const fileId = this.getFileIdFromDriveUrl(vendorNdaFile.value);

            console.log('File ID of NDA: ', fileId);

            return this.getDriveFileById(fileId);
        } catch (err) {
            console.error('Error while getting vendor nda: ', err);
        }


    }

    /***********************/

    public getBudgetImages(budgetFolder, imageFolderPath): Observable<any> {

        return this.googleApiService.onLoad().pipe(switchMap(() => {
            console.log('Gapi ready', gapi);
            return gapi.client.load('drive', 'v3').then(() => {
                return this.getImagesFromFolder(gapi, budgetFolder, imageFolderPath);
            });
            // return Observable.of(this.callScriptFunction(gapi, opt));
        }));
    }

    getInvoiceItemsWithoutIds() {
        return this.cDR.collection<any>(INVOICES_COLLECTION).ref.get().then(invoices => {

            const invoicesData = invoices.docs.map(invDoc => invDoc.data());

            const result = invoicesData.filter(inv => !inv.items.every(item => item.id !== undefined));

            console.log(result);

            return result;
        });
    }

    public askBudgetModifyAuth(budget): Observable<any> {
        const param = {budget: budget};
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                const sett = this.configService.settings.value;
                const endPoint = sett.value.firebaseApi + '/budget/askBudgetModifyAuth';
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                if (tid) {
                    return this.http.post(endPoint, param, {
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    /***************************/

    public ackBudgetModifyAuth(budget, isAuth): Observable<any> {
        const param = {budget: budget, isAuth: isAuth};
        return this.auth.getUserIdToken().pipe(
            first(),
            switchMap((tid) => {
                const sett = this.configService.settings.value;
                const endPoint = sett.value.firebaseApi + '/budget/ackBudgetModifyAuth';
                const headers = new HttpHeaders({
                    'Authorization': `Bearer ${tid}`,
                    'X-App-Domain': this.auth.appDomain
                });
                if (tid) {
                    return this.http.post(endPoint, param, {
                        headers: headers
                    });
                } else {
                    throw Error('No token');
                }
            }));
    }

    public getStatReportsList = (user: AclUser) => {
        const thisStartWeek = moment().startOf('isoWeek').toDate();
        const q = (ref) => ref.orderBy('creationDate', 'desc')
            .where('user.googleUid', '==', user.googleUid)
            .where('creationDate', '<', thisStartWeek).limit(50);
        const statReportCollRef = this.cDR.collection<StatReport>(STATREPORTS_COLLECTION, q);
        return statReportCollRef.snapshotChanges();
    }

    public getSingleStatReport = (user: AppUser, date: Date) => {
        const startDate = moment(date).tz('Europe/Rome').startOf('isoWeek').toDate();
        const endDate = moment(date).tz('Europe/Rome').endOf('isoWeek').toDate();
        console.log('getting single stat report:', startDate, endDate, user.email);
        const q = (ref) => ref.where('user.email', '==', user.email)
            .where('creationDate', '>=', startDate).where('creationDate', '<=', endDate);
        const statReportCollRef = this.cDR.collection<StatReport>(STATREPORTS_COLLECTION, q);
        return statReportCollRef.snapshotChanges();
    }

    public updateStatReport = (reportId: string, statReport: StatReport) => {
        const statReportRef = this.cDR.collection<StatReport>(STATREPORTS_COLLECTION).doc(reportId);
        return statReportRef.update(statReport);
    }

    public addStatReport = (statReport: StatReport) => {
        const statReportCollRef = this.cDR.collection<StatReport>(STATREPORTS_COLLECTION);
        return statReportCollRef.add(statReport);
    }

    public deleteStatReport = (reportId: string) => {
        const statReportCollRef = this.cDR.collection<Budget>(STATREPORTS_COLLECTION);
        return from(statReportCollRef.doc(reportId).delete());
    }

    public getStatFields = () => {
        const roleCollRef = this.cDR.collection<StatField>(STATFIELDS_COLLECTION);
        return roleCollRef.snapshotChanges();
    }

    private _getBudgetCollectionRef = (queryFn?: QueryFn) => {
        if (!queryFn) {
            queryFn = <QueryFn>(ref => ref.orderBy('creation', 'desc').limit(500));
        }
        console.log('qf', queryFn);
        return this.configService.settings.pipe(
            filter((data) => data !== null),
            map((sett) => {
                const bc = sett.value.budgetCollection;
                return this.cDR.collection<Budget>(bc, queryFn);
            }));
    }

    private buildOrderNr = (num: number, user: Partial<AclUser>) => {
        const paddedNum = num.toString().padStart(4, '0');
        const year = (new Date).getFullYear();
        return user.initials + 'OF ' + paddedNum + '-' + year;
    }


    /************************/
    /** Stat Report methods */

    /************************/

    private calculateOrderTotalInvoiceAmount(order: Order): Order {
        if (order.totalValue && typeof (order.totalValue) === 'string') {
            order.totalValue = Number(order.totalValue);
        }

        let totalInvoiceAmount = 0;
        for (const item of order.items) {
            if (item.invoices) {
                for (const invoice of item.invoices) {
                    totalInvoiceAmount += invoice.materialAmount;
                }
            }
        }

        order.orderInvoiceCompletion = order.totalValue ? ((1 / order.totalValue) * totalInvoiceAmount) : 1;
        order['invoiceRemaining'] = order.totalValue ? order.totalValue - totalInvoiceAmount : 0;

        return order;
    }

    /** Invoices methods   */
    /********************* */
    private buildInvoicesNr = (num: number, user: Partial<AclUser>) => {
        const paddedNum = num.toString().padStart(4, '0');
        const year = (new Date).getFullYear();
        return user.initials + 'OF ' + paddedNum + '-' + year;
    }

    /** Ddt methods        */
    /********************* */
    private buildDdtNr = (num: number, user: Partial<AclUser>) => {
        const paddedNum = num.toString().padStart(4, '0');
        const year = (new Date).getFullYear();
        return user.initials + 'OF ' + paddedNum + '-' + year;
    }

    /**
     * Load the API and make an API call.  Display the results on the screen.
     */
    private callScriptFunction = (gapi, scriptId: string, funcName: string, opt) => {


        // Create execution request.
        const request = {
            // 'function': 'compilaOfferta',
            'function': funcName,
            'parameters': [opt],
            'devMode': false   // Optional.
        };

        // Make the request.
        const op = gapi.client.request({
            'root': 'https://script.googleapis.com',
            'path': 'v1/scripts/' + scriptId + ':run',
            'method': 'POST',
            'body': request
        });

        // // Log the results of the request.
        // const execute =  op.execute(function (resp) {
        //   if (resp.error && resp.error.status) {
        //     // The API encountered a problem before the script started executing.
        //     // console.log('Error calling API: ' + JSON.stringify(resp, null, 2));
        //     throw Error(`Error calling API: ${ JSON.stringify(resp, null, 2) }`);
        //   } else if (resp.error) {
        //     // The API executed, but the script returned an error.
        //     const error = resp.error.details[0];
        //     throw Error(`Script error! Message: ${error.errorMessage}`);
        //   } else {
        //     return resp;
        //   }
        // });

        return op.then((resp) => {
            if (resp.error && resp.error.status) {
                // The API encountered a problem before the script started executing.
                // console.log('Error calling API: ' + JSON.stringify(resp, null, 2));
                throw Error(`Error calling API: ${JSON.stringify(resp, null, 2)}`);
            } else if (resp.error) {
                // The API executed, but the script returned an error.
                const error = resp.error.details[0];
                throw Error(`Script error! Message: ${error.errorMessage}`);
            } else {
                return resp.result;
            }
        });

    }

    private getFileIdFromDriveUrl(driveUrl: string) {
        // https://drive.google.com/open?id=1F9tAFBVE3DoCPT8KngEAxQqUZnGMigzj
        // OR https://drive.google.com/file/d/1BexUs7KGhAHrSDnkL-1boW1DtnlFDNbn/view?usp=share_link

        let tokens = driveUrl.split(`?id=`);
        console.log('first url split attempt:', tokens);
        if (tokens.length === 2) {
            return tokens[1];
        }

        tokens = driveUrl.split(`/`);
        console.log('second url split attempt:', tokens);
        if (tokens.length > 5) {
            return tokens[5];
        }

        return '';
    }

    private async getFolderByPath(gapi, rootId, path) {

        const currentFolder = path.split('/')[0];

        // await gapi.client.load('drive', 'v3');

        const resp = await gapi.client.drive.files.list({
            supportsTeamDrives: true,
            includeTeamDriveItems: true,
            q: `'${rootId}' in parents and name = '${currentFolder}' and trashed = false`
        });

        console.log('RESP', currentFolder, resp);

        if (resp.result.files.length === 0) {
            return false;
        } else if (path.split('/').length === 1) {
            return resp.result.files[0];
        } else {
            return this.getFolderByPath(gapi, resp.result.files[0].id, path.replace(currentFolder + '/', ''));
        }

    }

    private async getImagesFromFolder(gapi, folderId, imageFolderPath) {


        const imageFolder = await this.getFolderByPath(gapi, folderId, imageFolderPath);

        if (imageFolder) {
            // return gapi.client.load('drive', 'v3').then(() => {
            return gapi.client.drive.files.list({
                supportsTeamDrives: true,
                includeTeamDriveItems: true,
                q: `'${imageFolder.id}' in parents and trashed = false`,
                fields: '*'
            })
                .then((resp) => {
                    if (resp.error && resp.error.status) {
                        // The API encountered a problem before the script started executing.
                        // console.log('Error calling API: ' + JSON.stringify(resp, null, 2));
                        throw Error(`Error calling API: ${JSON.stringify(resp, null, 2)}`);
                    } else if (resp.error) {
                        // The API executed, but the script returned an error.
                        const error = resp.error.details[0];
                        throw Error(`Script error! Message: ${error.errorMessage}`);
                    } else {
                        return resp.result;
                    }
                }).catch((err) => {
                    console.log('Promise error ', err);
                });
        } else {
            console.log('Folder not found');
            return false;
        }
    }

}
