import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OpenProjectDialogComponent} from '../open-project-dialog/open-project-dialog.component';



@Component({
    selector: 'app-contact-picker-dialog',
    templateUrl: './contact-picker-dialog.component.html'
})
export class ContactPickerDialogComponent implements OnInit {

    public showOnlyUser = false;

    public filteredOptions: Observable<string[]>;
    public myControl: FormControl = new FormControl(undefined,
        Validators.required);
    public deliveryDateControl: FormControl = new FormControl(undefined,
        Validators.required);
    public noteToPrjMgr: FormControl = new FormControl();
    public minDate = new Date();

    constructor(
        public dialogRef: MatDialogRef<ContactPickerDialogComponent | OpenProjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.showOnlyUser = data.showOnlyUser;
    }

    ngOnInit() {

    }

    closeDialog() {
        if ((this.myControl.valid && this.deliveryDateControl.valid) || (this.showOnlyUser && this.myControl.valid)) {
            this.dialogRef.close({
                user: this.myControl.value,
                deliveryThr: this.deliveryDateControl.value,
                noteToPrjMgr: this.noteToPrjMgr.value
            });
        } else {
            this.deliveryDateControl.markAsTouched({onlySelf: true});
        }
    }


    onNoClick(): void {
        this.dialogRef.close();
    }

}
